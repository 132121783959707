import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'hexagon',
    url: 'home',
    abilitySubject: 'Home'
  },
  {
    id: 'inventario',
    title: 'INVENTARIO',
    type: 'section',
    url: 'inventario',
    abilitySubject: 'Inventario',
    children: [
      {
        id: 'inv-propiedades',
        title: 'Propiedades',
        type: 'item',
        icon: 'home',
        url: 'inventario/propiedades',
        abilitySubject: 'Inventario'
      },
      {
        id: 'mapa-propiedades',
        title: 'Mapa',
        type: 'item',
        icon: 'map',
        url: 'mapa',
        abilitySubject: 'Inventario'
      }
    ]
  },
  {
    id: 'contactos',
    title: 'CONTACTOS',
    type: 'section',
    url: 'contactos',
    abilitySubject: 'Partners',
    children: [
      {
        id: 'index-contactos',
        title: 'Contactos',
        type: 'item',
        icon: 'users',
        url: 'contactos',
        abilitySubject: 'Partners'
      },
      {
        id: 'index-partners',
        title: 'Partners',
        type: 'item',
        icon: 'briefcase',
        url: 'partners',
        abilitySubject: 'Partners'
      }
    ]
  },
  {
    id: 'captacion',
    title: 'CAPTACIÓN',
    type: 'section',
    url: 'captacion',
    abilitySubject: 'Captacion',
    children: [
      {
        id: 'capt-propiedades',
        title: 'Embudo',
        type: 'item',
        icon: 'filter',
        url: 'captacion/embudo',
        abilitySubject: 'Captacion'
      },
      {
        id: 'capt-index',
        title: 'Listado',
        type: 'item',
        icon: 'home',
        url: 'captacion/listado',
        abilitySubject: 'Captacion'
      }
    ]
  },
  {
    id: 'venta',
    title: 'Venta',
    type: 'section',
    url: 'venta',
    abilitySubject: 'Venta',
    children: [
      {
        id: 'venta-oportunidades',
        title: 'Oportunidades',
        type: 'item',
        icon: 'sunrise',
        url: 'venta/oportunidades',
        abilitySubject: 'Venta'
      },
      {
        id: 'venta-embudo',
        title: 'Gestión venta',
        type: 'item',
        icon: 'shopping-cart',
        url: 'venta/embudo',
        abilitySubject: 'Venta'
      }
    ]
  },
  {
    id: 'cms',
    title: 'Marketing',
    type: 'section',
    url: 'cms',
    abilitySubject: 'Marketing',
    children: [
      {
        id: 'cms-dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'hexagon',
        url: 'cms/dashboard',
        abilitySubject: 'Marketing',
      },
      {
        id: 'cms-publicaciones',
        title: 'Publicaciones',
        type: 'item',
        icon: 'home',
        url: 'cms/publicaciones',
        abilitySubject: 'Marketing',
      },
      {
        id: 'cms-grupos',
        title: 'Grupos',
        type: 'collapsible',
        icon: 'grid',
        abilitySubject: 'Marketing',
        children: [
          {
            id: 'cms-grupos-esenciales',
            title: 'Esenciales',
            icon: 'circle',
            type: 'item',
            url: 'cms/grupos/esenciales',
            abilitySubject: 'Marketing',
          },
          {
            id: 'cms-grupos-marketing',
            title: 'Marketing',
            icon: 'circle',
            type: 'item',
            url: 'cms/grupos/dinamicos/marketing',
            abilitySubject: 'Marketing',
          },
          {
            id: 'cms-grupos-geograficos',
            title: 'Geográficos',
            icon: 'circle',
            type: 'item',
            url: 'cms/grupos/dinamicos/geografico',
            abilitySubject: 'Marketing',
          }
        ]
      },
      {
        id: 'cms-noticias',
        title: 'Noticias',
        type: 'collapsible',
        icon: 'message-square',
        abilitySubject: 'Marketing',
        children: [
          {
            id: 'cms-noticias-blog',
            title: 'Blog',
            icon: 'circle',
            type: 'item',
            url: 'cms/noticias/blog',
            abilitySubject: 'Marketing',
          },
          {
            id: 'cms-noticias-prensa',
            title: 'Prensa',
            icon: 'circle',
            type: 'item',
            url: 'cms/noticias/prensa',
            abilitySubject: 'Marketing',
          }
        ]
      },
      {
        id: 'banners',
        title: 'Banners',
        type: 'item',
        icon: 'trello',
        url: 'cms/banners',
        abilitySubject: 'Marketing',
      }
    ]
  }

]
