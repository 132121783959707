import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ListManagerService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastSvc: ToastrService
  ) { }

  initializePagination() {
    return {
      page: 1,
      size: 12,
      totalElements: 0,
      totalPages: 0,
      display_as: 'table'
    };
  }

  loadInitialFilters(filterForm: FormGroup) {
    const params = this.activatedRoute.snapshot.queryParams;
    if (Object.keys(params).length !== 0) {
      filterForm.patchValue(params, { emitEvent: false });
      filterForm.markAsDirty();
    }
  }

  prepareFilters(filterForm: FormGroup, pagination: any) {
    const filters = {};
    Object.assign(filters, filterForm.value);

    // Limpia filtros vacíos
    for (const key in filters) {
      if (filters[key] === null || filters[key] === '') {
        delete filters[key];
      }
    }

    return {
      ...filters,
      page: pagination.page,
      limit: pagination.size
    };
  }

  handleLoadError(pagination: any) {
    this.clearPagination(pagination);
    return throwError(() => 'Error cargando datos');
  }

  clearPagination(pagination: any) {
    pagination.page = 1;
    pagination.totalElements = 0;
    pagination.totalPages = 0;
  }

  updateUrlFilters(filters: any) {
    this.router.navigate(
      [],
      {
        queryParams: filters,
        relativeTo: this.activatedRoute,
        replaceUrl: true
      }
    );
  }
}