import { Type } from 'class-transformer';
import { Usuario, Propiedad, Contacto } from 'app/models';




export interface IMensaje {
    id: number,
    created: Date,
    autor_id: number,
    mensaje: string,
    mensaje_tokenizado: any[],
    autor: Usuario,
    usuarios: Usuario[],
    propiedades: Propiedad[],
    contactos: Contacto[],
}

export class Mensaje implements IMensaje {
    public id: number;
    public created: Date;
    public autor_id: number;
    public mensaje: string;
    public mensaje_tokenizado: any[];
    @Type(() => Usuario)
    public autor: Usuario;
    @Type(() => Usuario)
    public usuarios: Usuario[];
    @Type(() => Propiedad)
    public propiedades: Propiedad[];
    @Type(() => Contacto)
    public contactos: Contacto[];

    static get modelName() {
      return 'Mensaje'
    }

    tokenizeMensaje() {

        let tokens = this.mensaje.split(' ');
        let words = [];

        tokens.forEach(token => {
          if (token.startsWith('#')) {
            let propiedad_id = parseInt(token.substring(1));
            let propiedad = this.propiedades.find(propiedad => propiedad.id == propiedad_id);
            if (propiedad) {
              words.push({word: token, id: propiedad.id, url: '/inventario/propiedades'});
            } else {
              words.push({word: token});
            }
          }
          else if (token.startsWith('$')) {
            let contacto_nombre_primera_palabra = token.substring(1); // Quitar el $
            let contacto = null;

            let matchingContactos = this.contactos.filter(contacto => contacto.nombre.startsWith(contacto_nombre_primera_palabra));

            // Intentar encontrar un contacto que coincida con el nombre completo
            for (let candidate of matchingContactos) {
              let nombreCompletoTokenizado = candidate.nombre.split(' ');
              let i = 1;

              while (i < nombreCompletoTokenizado.length && tokens[tokens.indexOf('$'+contacto_nombre_primera_palabra) + i] == nombreCompletoTokenizado[i]) {
                i++;
              }
              if (i == nombreCompletoTokenizado.length) {
                contacto = candidate;
                break;
              }
            }

            if (contacto) {
              words.push({word: '$'+contacto.nombre, id: contacto.id, url: '/contactos'});
              tokens.splice(tokens.indexOf('$'+contacto_nombre_primera_palabra), contacto.nombre.split(' ').length-1);
            } else {
              words.push({word: token});
            }
          }
          else if (token.startsWith('@')) {
            let usuario_primera_palabra = token.substring(1);  // Quitar el @
            let usuario = null;

            let matchingUsuarios = this.usuarios.filter(usuario => usuario.full_name.startsWith(usuario_primera_palabra));

            // Intentar encontrar un usuario que coincida con el nombre completo
            for (let usuario_candidato of matchingUsuarios) {
              let nombreCompletoTokenizado = usuario_candidato.full_name.split(' ');
              let i = 1;

              while (i < nombreCompletoTokenizado.length && tokens[tokens.indexOf('@'+usuario_primera_palabra) + i] == nombreCompletoTokenizado[i]) {
                i++;
              }
              if (i == nombreCompletoTokenizado.length) {
                usuario = usuario_candidato;
                break;
              }
            }

            //let usuario = this.usuarios.find(usuario => usuario.full_name == usuario_id);
            if (usuario) {
              words.push({word: '@'+usuario.full_name, id: usuario.id, url: '/usuario'});
              tokens.splice(tokens.indexOf('@'+usuario_primera_palabra), usuario.full_name.split(' ').length-1);
            } else {
              words.push({word: token});
            }
          }
          else {
            words.push({word: token});
          }
        });

        return words;
    }

}