// // currency.service.ts
// import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';
// import { map } from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root'
// })
// export class CurrencyService {
//   private apiKey = '54317104452a95829969da3d'; // Obtén tu API key de https://www.exchangerate-api.com
//   private baseUrl = `https://v6.exchangerate-api.com/v6/${this.apiKey}`;

//   constructor(private http: HttpClient) {}

//   getAvailableCurrencies(): Observable<string[]> {
//     return this.http.get<any>(`${this.baseUrl}/codes`)
//       .pipe(
//         map(response => response.supported_codes.map((code: string[]) => code[0]))
//       );
//   }

//   convertCurrency(amount: number, fromCurrency: string, toCurrency: string): Observable<number> {
//     return this.http.get<any>(`${this.baseUrl}/pair/${fromCurrency}/${toCurrency}/${amount}`)
//       .pipe(
//         map(response => response.conversion_result)
//       );
//   }
// }
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private apiUrl = 'https://api.exchangerate-api.com/v4/latest/EUR';

  constructor(private http: HttpClient) {}

  convertCurrency(amount: number, targetCurrency: string): Observable<number> {
    return this.http.get<any>(this.apiUrl).pipe(
      map(response => {
        const rate = response.rates[targetCurrency];
        if (!rate) {
          throw new Error(`Currency ${targetCurrency} not found`);
        }
        return Number((amount * rate).toFixed(2));
      }),
      catchError(error => {
        console.error('Currency conversion error:', error);
        throw error;
      })
    );
  }
}