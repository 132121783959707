import { Imagen } from "./imagen.model";
import { Type } from 'class-transformer';
import { Slideshow } from "./slideshow.model";
import { CmsPropiedad } from "./propiedad-cms.model";

export interface ISeoGrupoTranslation {
    id: number,
    locale: string,
    title: string,
    description: string,
    keywords: string
}

export interface ISeoGrupo {
    id: number,
    title: string,
    description: string,
    keywords: string,
    _translations: ISeoGrupoTranslation[]

}

export interface IGrupoTranslation {
    id: number,
    locale: string,
    slug: string,
    nombre: string,
    subtitulo: string,
    desc_corta: string,
    titulo1: string,
    texto1: string,
    titulo2: string,
    texto2: string,
    titulo3: string,
    texto3: string
}

export interface IGrupo {
    id: number,
    slug: string,
    activo: boolean,
    destacado: boolean,
    tipo: string,
    nombre: string,
    subtitulo: string,
    desc_corta: string,
    texto1: string,
    titulo2: string,
    texto2: string,
    titulo3: string,
    texto3: string,
    color: string,
    seo_grupo: ISeoGrupo,
    slideshow: Slideshow,
    imagen_destacada: Imagen,
    imagen_fondo: Imagen,
    imagen_secundaria: Imagen,
    icono: Imagen,
    galeria_imagenes: Imagen[],
    cms_propiedades: CmsPropiedad[]
}

export class Grupo implements IGrupo {
    public id: number;
    public slug: string;
    public activo: boolean;
    public visible: boolean;
    public destacado: boolean;
    public tipo: string;
    public nombre: string;
    public subtitulo: string;
    public desc_corta: string;
    public texto1: string;
    public titulo2: string;
    public texto2: string;
    public titulo3: string;
    public texto3: string;
    public color: string;
    public seo_grupo: ISeoGrupo;
    @Type(() => Slideshow)
    public slideshow: Slideshow;
    @Type(() => Imagen)
    public imagen_destacada: Imagen;
    @Type(() => Imagen)
    public imagen_fondo: Imagen;
    @Type(() => Imagen)
    public imagen_secundaria: Imagen;
    @Type(() => Imagen)
    public icono: Imagen;
    @Type(() => Imagen)
    public galeria_imagenes: Imagen[];
    public _translations: IGrupoTranslation[];
    @Type(() => CmsPropiedad)
    public cms_propiedades: CmsPropiedad[];

    getTipo(): Object {

        return Grupo;
    }
}




