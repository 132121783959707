import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/service';
import { Notificacion } from 'app/models';
import { ApiService } from './api.service';
import { SharedDataService } from './shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService implements OnDestroy {
  // Estado local de notificaciones no leídas
  private unreadNotifications = new BehaviorSubject<Notificacion[]>([]);
  public unreadNotifications$ = this.unreadNotifications.asObservable();

  private refreshSubscription;
  private destroy$ = new Subject<void>();
  private logged = false;

  constructor(
    private apiSvc: ApiService,
    private authSvc: AuthenticationService,
    private sharedDataSvc: SharedDataService
  ) {
    this.initializeService();
  }

  private initializeService(): void {
    // Suscripción a cambios de autenticación
    console.log('NOTIFICATIONS SERVICE: Init');
    this.authSvc.currentUser.subscribe(user => {
      if (user) {
        this.logged = true;
        this.startPollingAndListeners();
      }
      else {
        this.logged = false;
        this.stopPollingAndListeners();
        this.unreadNotifications.next([]);
      }
    });

    // Suscripción a cambios en notificaciones via SharedDataService
    this.sharedDataSvc.notificacion$
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event.type === 'modified') {
          this.fetchUnreadNotifications(); // los endpoints de notificaciones no devuelven las notis asi que hay que volver a cargar
        }
      });
  }

  private startPollingAndListeners(): void {
    // Carga inicial
    this.fetchUnreadNotifications();

    // Configurar polling cada minuto
    this.refreshSubscription = interval(60000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.fetchUnreadNotifications();
      });
  }

  private stopPollingAndListeners(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  private fetchUnreadNotifications(): void {

    if (!this.logged) return;

    this.apiSvc.getUnreadNotifications()
      .subscribe({
        next: (response) => {
          this.unreadNotifications.next(response.notificaciones);
        },
        error: (error) => {
          console.error('Error fetching notifications:', error);
        }
      });
  }

  // Métodos públicos que utilizan ApiService
  markAsRead(notificationId: number): Observable<any> {
    return this.apiSvc.putMarkAsRead(notificationId).pipe(
      tap(() => {
        // La actualización del estado local se maneja via SharedDataService
      })
    );
  }

  markAllAsRead(): Observable<any> {
    return this.apiSvc.postMarkAllAsRead().pipe(
      tap(() => {
        // La actualización del estado local se maneja via SharedDataService
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.stopPollingAndListeners();
  }
}
