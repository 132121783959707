import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreCommonModule } from '@core/common.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2TelInputModule } from 'ng2-tel-input';

import { InteresAddComponent } from './interes-add.component';

@NgModule({
  declarations: [
    InteresAddComponent,
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    Ng2TelInputModule,
  ],
  exports: [
    InteresAddComponent
  ]
})
export class InteresAddModule {}
