import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ContactoAddValidator {
  static alMenosUnContacto(control: AbstractControl): ValidationErrors | null {
    const telefonos = control.get('telefonos_contacto');
    const emails = control.get('emails_contacto');

    if (telefonos && telefonos.value.length > 0 && telefonos.value[0].telefono) {
      return null;
    }

    if (emails && emails.value.length > 0 && emails.value[0].email) {
      return null;
    }

    return { alMenosUnContacto: true };
  }
}