
import { Type } from 'class-transformer';
import { Contacto } from './contacto.model';
import { Usuario } from './usuario.model';
import { Relacion } from './relacion.model';
import { IOportunidadDestacada } from './oportunidad-destacada.model';
import { Precio } from './precio.model';


export interface IOportunidad {
    id: number,
    created: Date,
    modified: Date,
    closed: Date,
    comercial_id: number,
    contacto_id: number,
    estado: string,
    comercial: Usuario,
    intereses: Relacion[],
    contacto: Contacto,
    oportunidades_destacadas: IOportunidadDestacada[]
}

export class Oportunidad implements IOportunidad {
    public id: number;
    public created: Date;
    public modified: Date;
    public closed: Date;
    public comercial_id: number;
    public contacto_id: number;
    public estado: string;
    @Type(() => Usuario)
    public comercial: Usuario;
    @Type(() => Relacion)
    public intereses: Relacion[];
    @Type(() => Precio)
    public precios_negociados: Precio[];
    @Type(() => Contacto)
    public contacto: Contacto;
    public oportunidades_destacadas: IOportunidadDestacada[];

    static get modelName() {
        return 'Oportunidad'
    }

    isOportunidadDescatada(user_id: number) {
        if (!this.oportunidades_destacadas) {
            return false;
        }
        for (let i = 0; i < this.oportunidades_destacadas.length; i++) {
            if (this.oportunidades_destacadas[i].usuario_id == user_id) {
                return true;
            }
        }
        return false;
    }

    hasProblemsVenta() {
        let problems = 0;
        this.intereses.forEach(interes => {
            const problemas_venta = interes.propiedad.getProblemsVenta();
            const problemas_venta_num = problemas_venta.filter(problema => problema.status == 1).length; //filtrados los warnings status=2
            problems += problemas_venta_num;

            interes.propiedad.propietarios.forEach(propietario => {
                problems += propietario.getProblemsVenta().length;
            });
            interes.propiedad.compradores.forEach(comprador => {
                problems += comprador.getProblemsVenta().length;
            });
        });
        problems += this.contacto.getProblemsVenta().length;

        return problems > 0;
    }


}