import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

import { NotificationsService } from 'app/services';
import { Notificacion } from 'app/models';

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit, OnDestroy {
  // Public
  public notifications: Notificacion[] = [];

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  private destroy$ = new Subject<void>();

  constructor(
    private notificationsSvc: NotificationsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Suscribirse al observable de notificaciones no leídas
    this.notificationsSvc.unreadNotifications$
      .pipe(takeUntil(this.destroy$))
      .subscribe(notifications => {
        this.notifications = notifications;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeDropdown(): void {
    this.dropdown.close();
  }

  markAsRead(notification: Notificacion): void {
    this.notificationsSvc.markAsRead(notification.id).subscribe();
  }

  markAllAsRead(): void {
    this.notificationsSvc.markAllAsRead().subscribe(() => {
      this.closeDropdown();
    });
  }

  handleNotificationClick(notification: Notificacion): void {
    // Primero marcamos la notificación como leída
    this.notificationsSvc.markAsRead(notification.id).subscribe(() => {
      // Una vez marcada como leída, navegamos a la URL si existe
      const url = notification.getUrl();
      this.dropdown.close();
      if (url) {
        this.router.navigate([url]);
      }
    });
  }


}
