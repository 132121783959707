import { Component, OnInit, ViewChild, ElementRef, HostListener, Input } from '@angular/core';
import { CacheService, ApiService } from 'app/services';
import { ToastrService } from 'ngx-toastr';
import { Propiedad } from 'app/models';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'propiedades-alert',             
  templateUrl: './propiedades-alert.component.html',
  styleUrls: ['./propiedades-alert.component.scss'],
})

export class PropiedadesAlertComponent implements OnInit {

  loading: boolean = false;
  today: Date = new Date();

  propiedades: Propiedad[] = [];

  @Input() dias: number = null;

  constructor(
    private apiSvc: ApiService,
    private router: Router    
  ) {
  }

  ngOnInit(): void {

    this.apiSvc.getPropiedadesContratoVence(this.dias).subscribe({ 
      next: response => {
        if (response) {       
          let rows = [];
            response.propiedades.forEach(item => {
            rows.push(item); 
          });          
          this.propiedades = rows;
        }
      }, 
      error: err => {
        console.log(err); 
      }
    });
  }
 
  navegar(id) {

    this.router.navigate(['/inventario/propiedades/'+id]);
  }
}
