import { Injectable } from '@angular/core';
import { Ability, AbilityBuilder } from '@casl/ability';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { combineAll } from 'rxjs-compat/operator/combineAll';
import { subject } from '@casl/ability';
import { AuthenticationService } from 'app/auth/service';

//import { AuthenticationService } from '.';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  config: any = null;

  constructor(
  //  private authSvc: AuthenticationService,
    private authSvc: AuthenticationService,
    private ability: Ability,
  ) {
    this.init();
  }

  async init() {
    console.log('PERMISSIONS SERVICE: Init');

    this.authSvc.currentUser.subscribe(usuario => {
        if (usuario) {
            console.log('PERMISSIONS SERVICE: User logged in, setting permissions for ' + usuario.username + ' (' + usuario.role + ')' );
            this.updateAbility(usuario);
        }
        else {
            //borrar permisos
        }
    });

  }


  private updateAbility(usuario) {
    const { can, cannot, rules } = new AbilityBuilder(Ability);

    //Permisos generales menu
    can('mainMenuItem', 'Home');
    can('mainMenuItem', 'Inventario');
    can('mainMenuItem', 'Contactos');
    can('mainMenuItem', 'Catalogo Partner');
    can('mainMenuItem', 'Partners');
    if (['admin', 'gestor', 'captacion'].includes(usuario.role)) {
        can('mainMenuItem', 'Captacion');
    }
    if (['admin', 'gestor', 'ventas'].includes(usuario.role)) {
        can('mainMenuItem', 'Venta');
    }
    if (['admin', 'gestor', 'promocion'].includes(usuario.role)) {
        can('mainMenuItem', 'Marketing');
    }

    //Permisos propiedades
    can('addPrecio', 'Propiedad',  { estado: { $in: ['captacion', 'disponible'] } });


    this.ability.update(rules);
  }

}