import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from 'app/services';
import { Actividad, Usuario, Contacto, Propiedad } from 'app/models';
import { plainToClass } from 'class-transformer';
import { CalendarOptions, DatesSetArg, EventClickArg } from '@fullcalendar/core'; // useful for typechecking
import dayGridMonth from '@fullcalendar/daygrid';
import listMonth from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';

@Component({
  selector: 'citas-pendientes',             
  templateUrl: './citas-pendientes.component.html',
  styleUrls: ['./citas-pendientes.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'todo-application' }
})

export class CitasPendientesComponent implements OnInit {

  @Input() hook: string = null;

  loading: boolean = false;

  public citas = [];
  public event;
  usuarios: Usuario[] = [];
  contactos: Contacto[] = [];
  propiedades: Propiedad[] = [];
  rango = { start: null, end: null };

  public filter = {
    usuario_id: null,
    propiedad_id: null,
    contacto_id: null,
    tipos: ['reunion_captacion', 'reunion_venta', 'reunion_interna', 'cita_notarial', 'cita_reportaje', 'otra']
  }

  public calendarOptions: CalendarOptions = {
    headerToolbar: {
      start: 'prev,next, title',
      end: ''
    },
    plugins: [listMonth],
    initialView: 'listMonth',
    weekNumberCalculation: 'ISO',
    locale: esLocale,
    initialEvents: this.citas,
    weekends: true,
    editable: false,
    eventResizableFromStart: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: 2,
    navLinks: true,
    eventClassNames: this.eventClass.bind(this),
  };

  private _unsubscribeAll: Subject<any>;

  constructor(
    private apiSvc: ApiService,

  ) {

    this.calendarOptions.datesSet = (arg: DatesSetArg) => {
      this.loadEvents(arg.start, arg.end, this.filter);
    };

    this._unsubscribeAll = new Subject();    
  }

  ngOnInit(): void {

    if (this.hook) {
      this.apiSvc.getUsuarioActivo().subscribe({
        next: response => { 
          if (response) {          
            let usuario = plainToClass(Usuario, response.usuario);            
            this.filter.usuario_id = usuario.id;   

            this.loadEvents(this.rango.start, this.rango.end, this.filter);      
          }
        },
        error: err => {
          console.log(err);
        }
      });  
    } else {

      this.filter.usuario_id = null;  
      this.loadEvents(this.rango.start, this.rango.end, this.filter);      
    }          
    
  }

  loadEvents(start, end, filter) {

    this.rango.start = start;
    this.rango.end = end;
    start = new Date(start);
    start = start.getFullYear() + '-' + ('0' + (start.getMonth() + 1)).slice(-2) + '-' + ('0' + start.getDate()).slice(-2);
    end = new Date(end);
    end = end.getFullYear() + '-' + ('0' + (end.getMonth() + 1)).slice(-2) + '-' + ('0' + end.getDate()).slice(-2);

    this.apiSvc.getActividades({ q_tipo: 'reuniones', q_fecha_inicio: start, q_fecha_fin: end }).subscribe({
      next: (res) => {

        let temp = res.actividades;
        //añadimos a temp los campos star y end iguales a fecha y all day a false
        temp.forEach(element => {
          element.start = element.fecha;
          element.end = element.fecha_fin ? element.fecha_fin : element.fecha;
          element.allDay = false;
          element.title = element.titulo;
          element.cita =  plainToClass(Actividad, { ...element });
        });
        if (filter.tipos) {
          temp = temp.filter(event => filter.tipos.includes(event.tipo));
        }
        if (filter.usuario_id) {
          temp = temp.filter(event => event.usuarios.map(({ id }) => id).includes(filter.usuario_id));
        }
        this.citas = temp;
        this.calendarOptions.events = temp;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  eventClass(s) {
    const calendarsColor = {
      reunion_venta: 'success',
      reunion_captacion: 'info',
      reunion_interna: 'danger',
    };
    const colorName = calendarsColor[s.event._def.extendedProps.tipo];
    return `bg-light-${colorName}`;
  }


/*    
    this.hasta.setDate(this.hasta.getDate() + this.dias);

    var start = this.today.getFullYear() + '-' + ('0' + (this.today.getMonth() + 1)).slice(-2) + '-' + ('0' + this.today.getDate()).slice(-2);
    var end = this.hasta.getFullYear() + '-' + ('0' + (this.hasta.getMonth() + 1)).slice(-2) + '-' + ('0' + this.hasta.getDate()).slice(-2);

    this.apiSvc.getActividades({ q_tipo: 'reuniones', q_fecha_inicio: start, q_fecha_fin: end }).subscribe({
      next: (res) => {

        let temp = res.actividades;

        //añadimos a temp los campos star y end iguales a fecha y all day a false
        temp.forEach(element => {
          element.start = element.fecha;
          element.end = element.fecha_fin ? element.fecha_fin : element.fecha;
          element.allDay = false;
          element.title = element.titulo;
          element.cita =  plainToClass(Actividad, { ...element });
        });

        console.log(temp);
      },
      error: (err) => {
        console.log(err);
      }
    });
  } 
  */
}
