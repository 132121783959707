// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	hmr: false,
	MEDIA_ADDRESS: 'https://backend.gch.noro.dev',
	AUTH_SERVER_ADDRESS: 'https://backend.gch.noro.dev/api/v1',
	API_SERVER_ADDRESS: 'https://backend.gch.noro.dev/api/v1',
	// MEDIA_ADDRESS: 'https://backend.grupocountryhomes.com',
	// AUTH_SERVER_ADDRESS: 'https://backend.grupocountryhomes.com/api/v1',
	// API_SERVER_ADDRESS: 'https://backend.grupocountryhomes.com/api/v1',

	PUBLIC_ADDRESS: 'https://www.grupocountryhomes.com/',

	cache_auto_refresh: 60000 * 10,

	portales: {

		kyero_id: 2,
		idealista_id: 3,
		thinkspain_id: 4,
		fotocasa_id: 5,
		spainhouses_id: 6,
		cocampo_id: 7,		
	},

	idiomas: ['es', 'en', 'fr', 'de', 'pt'], // 20240611 - ACP. Se añadió esto para ordenar los idiomas en los modales de Grupos, Noticias y Enlace de prensa. No es solución elegante (habrá que cambiarlo)

  	idiomas_requeridos_gch: [
    	{"idiomas":[
			{"iso":"es","nombre":"Español"},
            {"iso":"en","nombre":"Inglés"},
			{"iso":"fr","nombre":"Francés"},
            {"iso":"de","nombre":"Alemán"},
			{"iso":"pt","nombre":"Portugués"},
        ]
    }],
	precio_kilometro: 0.25,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
