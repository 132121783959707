<!-- app-content-header start -->
<ng-container *ngIf="contentHeader">
  <div class="content-header row">
    <div class="content-header-left col-md-9 col-12 mb-2">
      <div class="row breadcrumbs-top">
        <div class="col-12 d-flex">
          <h2 class="content-header-title float-left mb-0">
            {{ contentHeader.headerTitle }}
          </h2>
          <!-- app-breadcrumb component -->
          <app-breadcrumb [breadcrumb]="contentHeader.breadcrumb"></app-breadcrumb>
        </div>
      </div>
    </div>
    <ng-container *ngIf="contentHeader.actionButton || contentHeader.addButton || contentHeader.embedComponent">
      <div class="content-header-right text-md-right col-md-3 col-12 d-flex flex-row justify-content-end">
        <div class="form-group breadcrum-right" *ngIf="contentHeader.embedComponent">
          <ng-content></ng-content>
        </div>

        <div class="form-group breadcrum-right" *ngIf="contentHeader.addButton">
          <button
            class="btn-icon btn btn-primary btn-round btn-sm"
            type="button"
            rippleEffect
            (click)="action('add')"
          >
            <span [data-feather]="'plus'"></span>
          </button>
        </div>
        <div class="form-group breadcrum-right ml-50" *ngIf="contentHeader.actionButton">
          <div ngbDropdown>
            <button
              ngbDropdownToggle
              id="dropdownSettings"
              class="btn-icon btn btn-primary btn-round btn-sm"
              type="button"
              rippleEffect
            >
              <span [data-feather]="'grid'"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownSettings">
              <a ngbDropdownItem [routerLink]="['/actividades/tareas']"
                ><span [data-feather]="'check-square'" [class]="'mr-50'"></span> Tareas</a
              >
              <a ngbDropdownItem [routerLink]="['/mensajes']"
                ><span [data-feather]="'message-square'" [class]="'mr-50'"></span> Mensajes</a
              >
              <a ngbDropdownItem [routerLink]="['/actividades/citas']"
                ><span [data-feather]="'calendar'" [class]="'mr-50'"></span> Calendario</a
              >
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
<!-- app-content-header end -->
