import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Relacion, Contacto, Propiedad, Notificacion } from '../models';

type EntityType = Relacion | Contacto | Propiedad | Notificacion;
type ActionType = 'new' | 'modified' | 'deleted';

interface EntityEvent<T> {
  type: ActionType;
  data: T;
}

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {

  private relacionStream = new Subject<EntityEvent<Relacion>>();
  private propiedadStream = new Subject<EntityEvent<Propiedad>>();
  private contactoStream = new Subject<EntityEvent<Contacto>>();
  private notificacionStream = new Subject<EntityEvent<Notificacion>>();

  // Observables públicos
  public relacion$ = this.relacionStream.asObservable();
  public propiedad$ = this.propiedadStream.asObservable();
  public contacto$ = this.contactoStream.asObservable();
  public notificacion$ = this.notificacionStream.asObservable();

  public emitNew(entity: EntityType): void {
    this.emitEntity(entity, 'new');
  }

  public emitModified(entity: EntityType): void {
    this.emitEntity(entity, 'modified');
  }

  public emitDeleted(entity: EntityType): void {
    console.log('emitDeleted', entity);
    this.emitEntity(entity, 'deleted');
  }

  private emitEntity(entity: EntityType, type: ActionType): void {
    const event = { type, data: entity };

    if (entity instanceof Relacion) {
      this.relacionStream.next({ ...event, data: entity as Relacion });
    }
    else if (entity instanceof Propiedad) {
      this.propiedadStream.next({ ...event, data: entity as Propiedad });
    }
    else if (entity instanceof Contacto) {
      this.contactoStream.next({ ...event, data: entity as Contacto });
    }
    else if (entity instanceof Notificacion) {
      this.notificacionStream.next({ ...event, data: entity as Notificacion });
    }
  }
}