import { Type } from 'class-transformer';




export interface IParcela {
    id: number,
    created: Date,
    modified: Date,
    propiedad_id: number,
    nombre: string,
    parcela_escrituras: number,
    parcela_registro: number,
    parcela_catastro: number,
    construido_escrituras: number,
    construido_registro: number,
    construido_catastro: number,
    edificacion_escrituras: number,
    edificacion_registro: number,
    edificacion_catastro: number,
    huerta_escrituras: number,
    huerta_registro: number,
    huerta_catastro: number,
}

export class Parcela implements IParcela {
    public id: number;
    public created: Date;
    public modified: Date;
    public propiedad_id: number;
    public nombre: string;
    public parcela_escrituras: number;
    public parcela_registro: number;
    public parcela_catastro: number;
    public construido_escrituras: number;
    public construido_registro: number;
    public construido_catastro: number;
    public edificacion_escrituras: number;
    public edificacion_registro: number;
    public edificacion_catastro: number;
    public huerta_escrituras: number;
    public huerta_registro: number;
    public huerta_catastro: number;


    static get modelName() {
        return 'Parcela'
    }

    getSumaEscrituras(): number {
        return (this.parcela_escrituras || 0)+(+this.construido_escrituras || 0)+(+this.edificacion_escrituras || 0)+(+this.huerta_escrituras || 0);
    }
    getSumaRegistro(): number {
        return (this.parcela_registro || 0)+(+this.construido_registro || 0)+(+this.edificacion_registro || 0)+(+this.huerta_registro || 0);
    }
    getSumaCatastro(): number {
        return (this.parcela_catastro || 0)+(+this.construido_catastro || 0)+(+this.edificacion_catastro || 0)+(+this.huerta_catastro || 0);
    }

    valorarSuperficie(superficie) {
        let arr = [];
        ['escrituras', 'registro', 'catastro'].forEach(src => {
          arr.push(this[superficie + '_' + src]);
        });
        arr = arr.slice().sort(function (a, b) {
          return a - b;
        });
        var percent = (((arr[arr.length - 1] - arr[0]) / ((arr[arr.length - 1] + arr[0]) / 2)) * 100).toFixed(1);

        if (percent == 'NaN') {
            return '-';
        }
        return percent;
      }

}