
import { Type } from 'class-transformer';
import { Propiedad } from './propiedad.model';
import { Contacto } from './contacto.model';
import { Oportunidad } from './oportunidad.model';

export interface ITipoRelacion {
    id: number,
    nombre: string
}


export interface IRelacion {
    id: number,
    created: Date,
    modified: Date,
    contacto_id: number,
    propiedad_id: number,
    oportunidad_id: number,
    tipo_relacion_id: number,
    notas: string,
    destacado: boolean,
    fecha_desde: Date,
    fecha_hasta: Date,
    tipo_relacion: ITipoRelacion,
    propiedad: Propiedad,
    contacto: Contacto,
    oportunidad: Oportunidad
}

export class Relacion implements IRelacion {
    public id: number;
    public created: Date;
    public modified: Date;
    public contacto_id: number;
    public propiedad_id: number;
    public oportunidad_id: number;
    public tipo_relacion_id: number;
    public notas: string;
    public destacado: boolean;
    public fecha_desde: Date;
    public fecha_hasta: Date;
    public tipo_relacion: ITipoRelacion;
    @Type(() => Propiedad)
    public propiedad: Propiedad;
    @Type(() => Contacto)
    public contacto: Contacto;
    @Type(() => Oportunidad)
    public oportunidad: Oportunidad;

    static get modelName() {
        return 'Relacion'
    }
}