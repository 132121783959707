import { environment } from 'environments/environment';

export interface IImagenTranslation {
    locale: string,
    slug: string,
    nombre: string,
    descripcion: string,
}

export interface IImagen {
    id: number,
    nombre: string,
    descripcion: string,
    activo: boolean,
    orden: number,
    img: string,
    img_dir: string,
    img_size: number,
    file_type: string,
    error: string,
    modified: Date,
    created: Date,
    foreign_key: number,
    model: string,
    grp: string,
    adapter: string,
    img_small: string,
    img_big: string
    img_medium: string
    img_original: string
    _translations: IImagenTranslation[]


}

export class Imagen implements IImagen {
    public id: number;
    public nombre: string;
    public descripcion: string;
    public activo: boolean;
    public orden: number;
    public img: string;
    public img_dir: string;
    public img_size: number;
    public file_type: string;
    public error: string;
    public modified: Date;
    public created: Date;
    public foreign_key: number;
    public model: string;
    public grp: string;
    public adapter: string;
    public img_small: string;
    public img_medium: string;
    public img_big: string;
    public img_original: string;
    public _translations: IImagenTranslation[]

    static get modelName() {
        return 'Imagen'
    }

    url_small() {
        
        if (!this.img_small) {
            return '';
        }
        if (this.adapter == 'local'){
            return environment.MEDIA_ADDRESS+this.img_small;
        }
        else if (this.adapter == 's3'){
            return environment.MEDIA_ADDRESS+this.img_small;
        }
        else {
            return '';
        }
    }
    url_medium() {
        if (!this.img_medium) {
            return '';
        }
        if (this.adapter == 'local'){
            return environment.MEDIA_ADDRESS+this.img_medium;
        }
        else if (this.adapter == 's3') {
            return environment.MEDIA_ADDRESS+this.img_medium;
        }
        else {
            return '';
        }
    }
    url_big() {
        if (!this.img_big) {
            return '';
        }
        if (this.adapter == 'local'){
            return environment.MEDIA_ADDRESS+this.img_big;
        }
        else if (this.adapter == 's3') {
            return environment.MEDIA_ADDRESS+this.img_big;
        }
        else {
            return '';
        }
    }
    url_original() {
        if (!this.img_original) {
            return '';
        }
        if (this.adapter == 'local'){
            return environment.MEDIA_ADDRESS+this.img_original;
        }
        else if (this.adapter == 's3') {
            return environment.MEDIA_ADDRESS+this.img_original;
        }
        else {
            return '';
        }
    }
}