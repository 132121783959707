import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ApiService } from 'app/services';
import { Actividad, Usuario } from 'app/models';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'tareas-pendientes',             
  templateUrl: './tareas-pendientes.component.html',
  styleUrls: ['./tareas-pendientes.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'todo-application' }
})

export class TareasPendientesComponent implements OnInit {

  loading: boolean = false;
  today: Date = new Date();

  actividades: Actividad[] = [];  

  @Input() dias: number = null;
  @Input() hook: string = null;

  constructor(
    private apiSvc: ApiService,
  ) {
  }

  ngOnInit(): void {

    let usuario_id = null;

    if (this.hook) {
      this.apiSvc.getUsuarioActivo().subscribe({
        next: response => { 
          if (response) {          
            let usuario = plainToClass(Usuario, response.usuario);            
            usuario_id = usuario.id;   

            this.leerActividades(usuario_id);            
          }
        },
        error: err => {
          console.log(err);
        }
      });  
    } else {

      this.leerActividades(usuario_id);
    }          
  } 

  leerActividades(usuario_id: number) {

    this.apiSvc.getActividadesPendientes(usuario_id).subscribe({ 
      next: response => {
        if (response) {       
          let rows = [];
          response.actividades.forEach(item => {
            rows.push(item); 
          });          
          this.actividades = rows;
        }
      }, 
      error: err => {
        console.log(err); 
      }
    });                       
  }
}
