import { Portal } from "./portal.model";
import { Type } from 'class-transformer';

export interface IEstadoPublicacionPortal {
    id: number,
    created: Date,
    modified: Date,
    cms_propiedad_id: number,
    portal_id: number,
    fecha: Date,
    estado: string,
    portal: Portal,
}

export class EstadoPublicacionPortal implements IEstadoPublicacionPortal {
    public id: number;
    public created: Date;
    public modified: Date;
    public cms_propiedad_id: number;
    public portal_id: number;
    public fecha: Date;
    public estado: string;
    @Type(() => Portal)
    public portal: Portal;
}