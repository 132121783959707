import { EstadoPublicacionLanding } from "./estado-publicacion-landing.model";
import { Type } from 'class-transformer';

export interface ILanding {
    id: number,
    created: Date,
    modified: Date,
    nombre: string,
    idiomas_requeridos: string,
    estados_publicaciones_landings: EstadoPublicacionLanding[]
}

export class Landing implements ILanding {
    public id: number;
    public created: Date;
    public modified: Date;
    public nombre: string;
    public idiomas_requeridos: string;
    @Type(() => EstadoPublicacionLanding)
    public estados_publicaciones_landings: EstadoPublicacionLanding[];
}

