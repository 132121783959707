import { Type } from 'class-transformer';




export interface IReferenciaCatastral {
    id: number,
    created: Date,
    modified: Date,
    propiedad_id: number,
    nombre: string,
    referencia: string,
    enlace: string
}

export class ReferenciaCatastral implements IReferenciaCatastral {
    public id: number;
    public created: Date;
    public modified: Date;
    public propiedad_id: number;
    public nombre: string;
    public referencia: string;
    public enlace: string;

    static get modelName() {
        return 'ReferenciaCatastral'
    }

    getReferencia() {
        const url = new URL(this.enlace);
        return url.searchParams.get('RefC');
    }
}
