import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefono'
})
export class TelefonoPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
        return '';
      }

      let result = '';
      let contador = 0;

      for (let i = value.length - 1; i >= 0; i--) {
        result = value[i] + result;
        contador++;

        if (contador === 3 && i !== 0) {
          result = ' ' + result;
          contador = 0;
        }
      }

      return result;
  }
}