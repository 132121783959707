import { JsonpInterceptor } from "@angular/common/http";

export interface INotificacion {
    id: number;
    title: string;
    body: string;
    template: string;
    created: Date;
    modified: Date;
    read: boolean;
    state: number;
    tracking_id: string;
    unread: boolean;
    user_id: number;
    vars: JSON;
}

export class Notificacion implements INotificacion {
    public id: number;
    public title: string;
    public body: string;
    public template: string;
    public created: Date;
    public modified: Date;
    public read: boolean;
    public state: number;
    public tracking_id: string;
    public unread: boolean;
    public user_id: number;
    public vars: JSON;

    static get modelName() {
        return 'Notificacion'
    }

    getIcon() {
        return 'bell';
    }
    getUrl() {
        if (this.template === 'nuevo_mensaje') {
            return '/mensajes';
        }
        return null;
    }
}
