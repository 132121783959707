import { Imagen } from "./imagen.model";
import { Type } from 'class-transformer';

export interface ICategoriaEnlacePrensa {
    id: number,
    created: Date,
    modified: Date,
    slug: string,
    nombre: string,
    titulo: string,
    descripcion: string
}


export interface IEnlacePrensaTranslation {
    id: number,
    locale: string,
    titulo: string,
    subtitulo: string,
}

export interface IEnlacePrensa {
    id: number,
    created: Date,
    modified: Date,
    titulo: string,
    subtitulo: string,
    categoria_enlace_prensa_id: number,
    usuario_id: number,
    enlace: string,
    youtube_link: string,
    fecha: Date,
    estado: string,
    categoria_enlace_prensa: ICategoriaEnlacePrensa,
    imagen_destacada: Imagen,
    _translations: IEnlacePrensaTranslation[]
}

export class EnlacePrensa implements IEnlacePrensa {

    public id: number;
    public created: Date;
    public modified: Date;
    public titulo: string;
    public subtitulo: string;
    public categoria_enlace_prensa_id: number;
    public usuario_id: number;
    public enlace: string;
    public youtube_link: string;
    public fecha: Date;
    public estado: string;
    public categoria_enlace_prensa: ICategoriaEnlacePrensa;
    @Type(() => Imagen)
    public imagen_destacada: Imagen;
    public _translations: IEnlacePrensaTranslation[];

    static get modelName() {
        return 'EnlacePrensa'
    }
}




