import { Type } from 'class-transformer';
import { Imagen } from './imagen.model';
import { Actividad } from './actividad.model';

export interface IUsuario {
    id: number,
    username: string,
    email: string,
    first_name: string,
    last_name: string,
    //activation_date: Date,
    //tos_date: Date,
    active: string,
    is_superuser: string,
    role: string,
    //created: Date,
    //modified: Date,
    additional_data: string,
    last_login: Date,
    imagen: Imagen,
    citas: Actividad[],
    partner_id: number,

}


export class Usuario implements IUsuario {
    public id: number;
    public username: string;
    public email: string;
    public first_name: string;
    public last_name: string;
    public full_name: string;
    public active: string;
    public is_superuser: string;
    public role: string;
    public created: Date;
    public modified: Date;
    public additional_data: string;
    public last_login: Date;
    @Type(() => Imagen)
    public imagen: Imagen;
    @Type(() => Actividad)
    public citas: Actividad[];
    public partner_id: number;

    static get modelName() {
        return 'Usuario'
    }

    getFullName(): string {
        return `${this.first_name} ${this.last_name}`
    }

    getImagen() {
        if (this.imagen) {
            return this.imagen;
        }
        return null;
    }


}