<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="home-page">

      <div class="row">


        <div class="col-8">
          <div class="card card-statistics">
            <div class="card-header">
              <h4 class="card-title">Propiedades</h4>
              <div class="d-flex align-items-center">
                <p class="card-text mr-25 mb-0"></p>
              </div>
            </div>
            <div class="card-body statistics-body">
              <div class="row">
                <div class="col-md-4 col-12 mb-2 mb-md-0">
                  <div class="media">
                    <div class="avatar bg-light-primary mr-2">
                      <div class="avatar-content">
                        <i data-feather="home" class="avatar-icon"></i>
                      </div>
                    </div>
                    <div class="media-body my-auto">
                      <h4 class="font-weight-bolder mb-0">{{countByStatus('disponible')}}</h4>
                      <p class="card-text font-small-3 mb-0">Disponibles</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12 mb-2 mb-md-0">
                  <div class="media">
                    <div class="avatar bg-light-warning mr-2">
                      <div class="avatar-content">
                        <i data-feather="home" class="avatar-icon"></i>
                      </div>
                    </div>
                    <div class="media-body my-auto">
                      <h4 class="font-weight-bolder mb-0">{{countByStatus('captacion')}}</h4>
                      <p class="card-text font-small-3 mb-0">Captacion</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="media">
                    <div class="avatar bg-light-success mr-2">
                      <div class="avatar-content">
                        <i data-feather="dollar-sign" class="avatar-icon"></i>
                      </div>
                    </div>
                    <div class="media-body my-auto">
                      <h4 class="font-weight-bolder mb-0">{{countByStatus('vendida')}}</h4>
                      <p class="card-text font-small-3 mb-0">Vendidas</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="card card-statistics">
            <div class="card-header">
              <h4 class="card-title">Directorio</h4>
              <div class="d-flex align-items-center">
                <p class="card-text mr-25 mb-0"></p>
              </div>
            </div>
            <div class="card-body statistics-body">
              <div class="row">
                <div class="col-12 mb-2 mb-md-0">
                  <div class="media">
                    <div class="avatar bg-light-info mr-2">
                      <div class="avatar-content">
                        <i data-feather="user" class="avatar-icon"></i>
                      </div>
                    </div>
                    <div class="media-body my-auto">
                      <h4 class="font-weight-bolder mb-0">{{contactos.length}}</h4>
                      <p class="card-text font-small-3 mb-0">Contactos</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div class="row">
        <div class="col-12 col-md-6">
          <propiedades-alert [dias]="30"></propiedades-alert>
          <citas-pendientes></citas-pendientes>
        </div>
        <div class="col-12 col-md-6">
          <propiedades-arras-alert [dias]="30"></propiedades-arras-alert>
          <tareas-pendientes></tareas-pendientes>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>