import { Type } from 'class-transformer';
import { Usuario } from "./usuario.model";
import { Actividad } from "./actividad.model";

export interface IGastoGestion {
    id: number,
    created: Date,
    modified: Date,
    actividad_id: number,
    usuario_id: number,
    kilometros: number,
    importe: number,
}

export class GastoGestion implements IGastoGestion {
    public id: number;
    public created: Date;
    public modified: Date;
    public actividad_id: number;
    public usuario_id: number;
    public kilometros: number;
    public importe: number;
    @Type(() => Usuario)
    public usuario: Usuario;    
    @Type(() => Actividad)
    public actividad: Actividad;    
}