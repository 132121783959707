import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule, ToastrService } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { AbilityModule } from '@casl/angular';
import { Ability, PureAbility } from '@casl/ability';

import { coreConfig } from 'app/app-config';
import { JwtInterceptor, ErrorInterceptor, AuthGuard} from 'app/auth/helpers';
import { LoadingInterceptor } from 'app/interceptors/loading-interceptor';
import { CacheService } from "./services";
import { PermissionsService } from "./services";
import { NotificationsService } from "./services";

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs, 'es');

const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'actividades',
    loadChildren: () => import('./main/actividades/actividades.module').then(m => m.ActividadesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mensajes',
    loadChildren: () => import('./main/mensajes/mensajes.module').then(m => m.MensajesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inventario',
    loadChildren: () => import('./main/inventario/inventario.module').then(m => m.InventarioModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mapa',
    loadChildren: () => import('./main/mapa/mapa.module').then(m => m.MapaModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'captacion',
    loadChildren: () => import('./main/captacion/captacion.module').then(m => m.CaptacionModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'venta',
    loadChildren: () => import('./main/venta/venta.module').then(m => m.VentaModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contactos',
    loadChildren: () => import('./main/contactos/contactos.module').then(m => m.ContactosModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./main/usuarios/usuarios.module').then(m => m.UsuariosModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cms',
    loadChildren: () => import('./main/cms/cms.module').then(m => m.CmsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'informes',
    loadChildren: () => import('./main/informes/informes.module').then(m => m.InformesModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy',
    }),
    TranslateModule.forRoot(),
    AbilityModule,

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot({
      enableHtml: true,
      toastClass: 'toast ngx-toastr',
      closeButton: true
    }),
    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "es-ES" },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    CacheService,
    PermissionsService,
    NotificationsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
