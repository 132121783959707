import { Type } from 'class-transformer';
import { MIN_COMISION } from 'app/constants/model-constants';
import { Propiedad } from './propiedad.model';

export interface IPrecio {
    id: number,
    created: Date,
    importe: number,
    neto_propietario: number,
    comision: number,
    propiedad_id: number,
    vigente: boolean,
    oportunidad_id: number,
    propiedad: Propiedad
}

export class Precio implements IPrecio {
    public id: number;
    public created: Date;
    public importe: number;
    public neto_propietario: number;
    public comision: number;
    public propiedad_id: number;
    public vigente: boolean;
    public oportunidad_id: number;
    @Type(() => Propiedad)
    public propiedad: Propiedad;

    static get modelName() {
        return 'Precio'
    }

    esVigente() {
        return this.vigente;
    }
    getImporteComision() {
        let valor = this.importe * (this.comision / 100);
        if (valor < MIN_COMISION && this.comision == 0) {
            valor = MIN_COMISION;
        }
        return valor;
    }
}