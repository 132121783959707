import { Type } from 'class-transformer';

import { Imagen } from './imagen.model';
import { Precio } from './precio.model';
import { IEtiqueta } from "./etiqueta.model";
import { Archivo } from './archivo-model';
import { Parcela } from './parcela.model';
import { Relacion } from './relacion.model';
import { Contacto } from './contacto.model';
import { ReferenciaCatastral } from './referencia-catastral.model';
import { ICaptacionDestacada } from './captacion-destacada.model';
import { Actividad } from './actividad.model';
import { PartnerPropiedad } from './partner-propiedad.model';
import { XolidoDocument } from './xolido-document.model';

export interface ICategoriaPropiedad {
    id: number,
    created: Date,
    modified: Date,
    slug: string,
    nombre: string,
    titulo: string,
    descripcion: string
}


export interface IPropiedad {
    id: number,
    created: Date,
    modified: Date,

    imagen_defecto_id: number,

    nombre: string,
    descripcion: string,
    estado: string,
    estado_captacion: string,

    localidad: string,
    provincia: string,
    direccion: string,
    pais: string,
    lat: number,
    lon: number,
    map_url: string,

    dormitorios: number,
    salaones: number,
    banos: number,
    otras_estancias: number,
    plantas: number,
    agua_check: boolean,
    agua: string,
    electricidad_check: boolean,
    electricidad: string,
    telefono_check: boolean,
    telefono: string,
    calefaccion_check: boolean,
    calefaccion: string,
    informacion_adicional_servicios: string,

    superficie_construida: number,
    superficie_total: number,

    // To be deprecated
    firma_contrato: Date,
    duracion_contrato: number,

    firma_arras: Date,
    duracion_arras: number,
    importe_arras: number,
    // -----

    contrato_tipo_origen: string,
    contrato_fecha: Date,
    contrato_duracion: number,

    arras_fecha: Date,
    arras_duracion: number,
    arras_importe: number,

    certificado_energetico_status: string,
    certificado_energetico_eficiencia_escala: string,
    certificado_energetico_eficiencia_valor: number,
    certificado_energetico_emisiones_escala: string,
    certificado_energetico_emisiones_valor: number,

    orientacion: string,
    amueblada: string,
    codigo_llaves: string,

    imagen_defecto: Imagen,
    imagenes_reportaje: Imagen[],
    otras_imagenes: Imagen[],
    archivos: Archivo[],
    xolido_documents: XolidoDocument[],
    //contrato: Archivo,
    //dossier: Archivo,
    //registro: Archivo,
    //escritura: Archivo,
    //certificacion_energetica: Archivo,
    //arras: Archivo,
    //justificante_arras: Archivo,
    //ibi: Archivo,
    etiquetas: IEtiqueta[],
    precio_actual: Precio,
    precios: Precio[],
    categoria_propiedad: ICategoriaPropiedad,
    parcelas: Parcela[],
    referencias_catastrales: ReferenciaCatastral[],
    relaciones: Relacion[],
    propietarios: Contacto[],
    compradores: Contacto[],
    captaciones_destacadas: ICaptacionDestacada[],
    tareas: Actividad[],
    citas_captacion: Actividad[],
    partners_propiedades: PartnerPropiedad[],
    citas: Actividad[],

    captacion_fotos: boolean,
    captacion_refcatastral: boolean,
    captacion_valoracion: boolean,
    captacion_condiciones_aceptadas: boolean,
}

export class Propiedad implements IPropiedad {
    public id: number;
    public created: Date;
    public modified: Date;

    public imagen_defecto_id: number;

    public nombre: string;
    public descripcion: string;
    public estado: string;
    public estado_captacion: string;

    public localidad: string;
    public provincia: string;
    public pais: string;
    public direccion: string;
    public lat: number;
    public lon: number;
    public map_url: string;

    public dormitorios: number;
    public salaones: number;
    public banos: number;
    public otras_estancias: number;
    public plantas: number;
    public agua_check: boolean;
    public agua: string;
    public electricidad_check: boolean;
    public electricidad: string;
    public telefono_check: boolean;
    public telefono: string;
    public calefaccion_check: boolean;
    public calefaccion: string;
    public informacion_adicional_servicios: string;

    public superficie_construida: number;
    public superficie_total: number;

    // To be deprecated
    public firma_contrato: Date;
    public duracion_contrato: number;

    public firma_arras: Date;
    public duracion_arras: number;
    public importe_arras: number;
    // -----

    public contrato_tipo_origen: string;
    public contrato_fecha: Date;
    public contrato_duracion: number;

    public arras_fecha: Date;
    public arras_duracion: number;
    public arras_importe: number;



    public certificado_energetico_status: string;
    public certificado_energetico_eficiencia_escala: string;
    public certificado_energetico_eficiencia_valor: number;
    public certificado_energetico_emisiones_escala: string;
    public certificado_energetico_emisiones_valor: number;

    public orientacion: string;
    public amueblada: string;
    public codigo_llaves: string;

    public captacion_fotos: boolean;
    public captacion_refcatastral: boolean;
    public captacion_valoracion: boolean;
    public captacion_condiciones_aceptadas: boolean;


    @Type(() => Imagen)
    public imagen_defecto: Imagen;
    @Type(() => Imagen)
    public imagenes_reportaje: Imagen[];
    @Type(() => Imagen)
    public otras_imagenes: Imagen[];
    @Type(() => Archivo)
    public archivos: Archivo[];
    @Type(() => XolidoDocument)
    public xolido_documents: XolidoDocument[];
    // @Type(() => Archivo)
    // public dossier: Archivo;
    // @Type(() => Archivo)
    // public registro: Archivo;
    // @Type(() => Archivo)
    // public contrato: Archivo;
    // @Type(() => Archivo)
    // public escritura: Archivo;
    // @Type(() => Archivo)
    // public certificacion_energetica: Archivo;
    // @Type(() => Archivo)
    // public arras: Archivo;
    // @Type(() => Archivo)
    // public justificante_arras: Archivo;
    // @Type(() => Archivo)
    // public ibi: Archivo;
    public etiquetas: IEtiqueta[];
    @Type(() => Precio)
    public precio_actual: Precio;
    @Type(() => Precio)
    public precios: Precio[];
    public categoria_propiedad: ICategoriaPropiedad;
    @Type(() => Parcela)
    public parcelas: Parcela[];
    @Type(() => ReferenciaCatastral)
    public referencias_catastrales: ReferenciaCatastral[];
    @Type(() => Relacion)
    public relaciones: Relacion[];
    @Type(() => Contacto)
    public propietarios: Contacto[];
    @Type(() => Contacto)
    public compradores: Contacto[];
    public captaciones_destacadas: ICaptacionDestacada[];
    @Type(() => Actividad)
    public tareas: Actividad[];
    @Type(() => Actividad)
    public citas_captacion: Actividad[];
    @Type(() => PartnerPropiedad)
    public partners_propiedades: PartnerPropiedad[];
    @Type(() => Actividad)
    public citas: Actividad[];

    static get modelName() {
        return 'Propiedad'
    }

    getContratoVencimiento() {
        if (this.contrato_fecha && this.contrato_duracion) {
            const fechaContrato = new Date(this.contrato_fecha);
            fechaContrato.setMonth(fechaContrato.getMonth() + this.contrato_duracion);
            return fechaContrato;
        }
        return null;
    }
    getContratoMesesRestantes() {
        if (this.contrato_fecha && this.contrato_duracion) {
            const fechaContrato = new Date(this.contrato_fecha);
            const fechaActual = new Date();
            const mesesRestantes = this.contrato_duracion - (fechaActual.getFullYear() - fechaContrato.getFullYear()) * 12 - (fechaActual.getMonth() - fechaContrato.getMonth());
            return mesesRestantes;
        }
        return null;
    }

    getArrasVencimiento() {
        if (this.arras_fecha && this.arras_duracion) {
            const fechaArras = new Date(this.arras_fecha);
            fechaArras.setMonth(fechaArras.getMonth() + this.arras_duracion);
            return fechaArras;
        }
        return null;
    }

    getArrasMesesRestantes() {
        if (this.arras_fecha && this.arras_duracion) {
            const fechaArras = new Date(this.arras_fecha);
            const fechaActual = new Date();
            const mesesRestantes = this.arras_duracion - (fechaActual.getFullYear() - fechaArras.getFullYear()) * 12 - (fechaActual.getMonth() - fechaArras.getMonth());
            return mesesRestantes;
        }
        return null;
    }

    getImagenDefecto() {
        if (this.imagen_defecto) {
            return this.imagen_defecto;
        }
        if (this.imagenes_reportaje.length > 0) {
            return this.imagenes_reportaje[0];
        }
        if (this.otras_imagenes.length > 0) {
            return this.otras_imagenes[0];
        }
        return null;
    }

    getContratoTipo(): 'Archivo' | 'XolidoDocument' | null {
        // Primero buscar en archivos normales
        if (this.archivos) {
            const contratoArchivo = this.archivos.find(archivo => archivo.grp === 'contrato' && archivo.activo === true);
            if (contratoArchivo) {
                return 'Archivo';
            }
        }
        // Luego buscar en documentos xolido
        if (this.xolido_documents) {
            const contratoXolido = this.xolido_documents?.find(
                doc => doc.grp === 'contrato'
            );
            if (contratoXolido) {
                return 'XolidoDocument';
            }
        }
        return null;
    }
    getContrato(): Archivo | XolidoDocument | null {
        if (this.archivos) {
            const contratoArchivo = this.archivos.find(archivo => archivo.grp === 'contrato' && archivo.activo === true);
            if (contratoArchivo) {
                return contratoArchivo;
            }
        }
        if (this.xolido_documents) {
            const contratoXolido = this.xolido_documents.find(doc => doc.grp === 'contrato');
            if (contratoXolido) {
                return contratoXolido;
            }
        }
        return null;
    }
    getEscritura() {
        if (!this.archivos) {
            return null;
        }
        return this.archivos.find(archivo => archivo.grp === 'escritura' && archivo.activo === true);
    }
    getRegistro() {
        if (!this.archivos) {
            return null;
        }
        return this.archivos.find(archivo => archivo.grp === 'registro' && archivo.activo === true);
    }
    getIbi() {
        if (!this.archivos) {
            return null;
        }
        return this.archivos.find(archivo => archivo.grp === 'ibi' && archivo.activo === true);
    }
    getCertificacionEnergetica() {
        if (!this.archivos) {
            return null;
        }
        return this.archivos.find(archivo => archivo.grp === 'certificacion_energetica' && archivo.activo === true);
    }
    getArras() {
        if (!this.archivos) {
            return null;
        }
        return this.archivos.find(archivo => archivo.grp === 'arras' && archivo.activo === true);
    }
    getJustificanteArras() {
        if (!this.archivos) {
            return null;
        }
        return this.archivos.find(archivo => archivo.grp === 'justificante_arras' && archivo.activo === true);
    }
    getDossier() {
        if (!this.archivos) {
            return null;
        }
        return this.archivos.find(archivo => archivo.grp === 'dossier' && archivo.activo === true);
    }
    getOtrosArchivos() {
        if (!this.archivos) {
            return null;
        }
        return this.archivos.filter(archivo => archivo.grp !== 'contrato' && archivo.grp !== 'escritura' && archivo.grp !== 'registro' && archivo.grp !== 'certificacion_energetica' && archivo.grp !== 'arras' && archivo.grp !== 'justificante_arras' && archivo.grp !== 'ibi' && archivo.activo === true);
    }
    getArchivados() {
        if (!this.archivos) {
            return null;
        }
        return this.archivos.filter(archivo => archivo.activo === false);
    }


    getProblems(all = false) {

        let problems = [
            {
                key: 'contrato',
                nombre: 'Contrato',
                msg: 'No hay contrato',
                ayuda: 'Es necesario subir contrato',
                status: false,
            },
            {
                key: 'firma_contrato',
                nombre: 'Fecha firma contrato',
                msg: 'No hay fecha de firma de contrato',
                ayuda: 'Es necesario indicar la fecha de firma',
                status: false,
            },
            {
                key: 'escritura',
                nombre: 'Escritura o  registro',
                msg: 'No hay escritura ni registro',
                ayuda: 'Es necesario subir escritura o registro',
                status: false,
            },
            {
                key: 'reportaje',
                nombre: 'Reportaje',
                msg: 'Reportaje incompleto',
                ayuda: 'Es necesario subir más fotos al reportaje ('+this.imagenes_reportaje.length+'/15)',
                status: false,
            },
            {
                key: 'precio',
                nombre: 'Precio',
                msg: 'No hay precio',
                ayuda: 'Es necesario asignar un escritura',
                status: false,
            },
            {
                key: 'propietarios',
                nombre: 'Propietarios',
                msg: 'No hay propietarios',
                ayuda: 'Es necesario indicar al menos un propietario',
                status: false,
            }
        ];

        if (!this.getContrato()) {
            problems = problems.map(p =>   p.key === 'contrato' ? { ...p, status: true } : p );
        }
        if (!this.contrato_fecha) { // isNaN wont accept a date in typescript, use date.getTime() instead to produce a number
            problems = problems.map(p =>   p.key === 'firma_contrato' ? { ...p, status: true } : p );
        }
        if (!this.getEscritura() && !this.getRegistro()) {
            problems = problems.map(p =>   p.key === 'escritura' ? { ...p, status: true } : p );
        }
        if (!this.imagenes_reportaje || this.imagenes_reportaje.length<15 ) {
            problems = problems.map(p =>   p.key === 'reportaje' ? { ...p, status: true } : p );
        }
        if (!this.referencias_catastrales || this.referencias_catastrales.length == 0 || this.pais == 'España') {
            problems = problems.map(p =>   p.key === 'referencia_catastral' ? { ...p, status: true } : p );
        }
        const propietarios = this.getPropietarios();
        if (propietarios.length == 0) {
            problems = problems.map(p =>   p.key === 'propietarios' ? { ...p, status: true } : p );
        }

        if (this.pais && this.pais == 'España') {
            problems.push({
                key: 'referencia_catastral',
                nombre: 'Referencia catastral',
                msg: 'No hay referencia catastral',
                ayuda: 'Es necesario adjuntar al menos una referencia catastral',
                status: false,
            });
            if (!this.precio_actual && (!this.precios || this.precios.length == 0)) {
                problems = problems.map(p =>   p.key === 'precio' ? { ...p, status: true } : p );
            }
        }

        if (!all) {
            let problems_return = problems.filter(p => p.status);
            return problems_return;
        }
        return problems;
    }
    hasProblem(param) {
        let problems = this.getProblems(true);
        let problem = problems.find(p => p.key == param);

        return problem.status;
    }
    getProblem(param) {
        let problems = this.getProblems(true);
        let problem = problems.find(p => p.key == param);

        return problem;
    }

    getProblemsCaptacion(all = false) {

        let problems = [
            {
                key: 'contrato',
                nombre: 'Contrato',
                msg: 'No hay contrato',
                ayuda: 'Es necesario subir contrato',
                status: false,
            },
            {
                key: 'firma_contrato',
                nombre: 'Fecha firma contrato',
                msg: 'No hay fecha de firma de contrato',
                ayuda: 'Es necesario indicar la fecha de firma',
                status: false,
            },
            {
                key: 'escritura',
                nombre: 'Escritura o  registro',
                msg: 'No hay escritura ni registro',
                ayuda: 'Es necesario subir escritura o registro',
                status: false,
            },
            {
                key: 'reportaje',
                nombre: 'Reportaje',
                msg: 'Reportaje incompleto',
                ayuda: 'Es necesario subir más fotos al reportaje ('+this.imagenes_reportaje.length+'/15)',
                status: false,
            },
            {
                key: 'precio',
                nombre: 'Precio',
                msg: 'No hay precio',
                ayuda: 'Es necesario indicar un precio',
                status: false,
            },
            {
                key: 'propietarios',
                nombre: 'Propietarios',
                msg: 'No hay propietarios',
                ayuda: 'Es necesario indicar al menos un propietario',
                status: false,
            }
        ];

        if (!this.getContrato()) {
            problems = problems.map(p =>   p.key === 'contrato' ? { ...p, status: true } : p );
        }
        if (!this.contrato_fecha) { // isNaN wont accept a date in typescript, use date.getTime() instead to produce a number
            problems = problems.map(p =>   p.key === 'firma_contrato' ? { ...p, status: true } : p );
        }
        if (!this.getEscritura() && !this.getRegistro()) {
            problems = problems.map(p =>   p.key === 'escritura' ? { ...p, status: true } : p );
        }
        if (!this.imagenes_reportaje || this.imagenes_reportaje.length<15 ) {
            problems = problems.map(p =>   p.key === 'reportaje' ? { ...p, status: true } : p );
        }
        if (!this.precio_actual && (!this.precios || this.precios.length == 0)) {
            problems = problems.map(p =>   p.key === 'precio' ? { ...p, status: true } : p );
        }
        if (this.pais && this.pais == 'España') {
            problems.push({
                key: 'referencia_catastral',
                nombre: 'Referencia catastral',
                msg: 'No hay referencia catastral',
                ayuda: 'Es necesario adjuntar al menos una referencia catastral',
                status: false,
            });
            if (!this.precio_actual && (!this.precios || this.precios.length == 0)) {
                problems = problems.map(p =>   p.key === 'precio' ? { ...p, status: true } : p );
            }
        }
        const propietarios = this.getPropietarios();
        if (propietarios.length == 0) {
            problems = problems.map(p =>   p.key === 'propietarios' ? { ...p, status: true } : p );
        }

        if (!all) {
            let problems_return = problems.filter(p => p.status);
            return problems_return;
        }
        
        return problems;
    }
    hasProblemCaptacion(param) {
        let problems = this.getProblemsCaptacion(true);
        let problem = problems.find(p => p.key == param);

        return problem.status;
    }
    getProblemCaptacion(param) {
        let problems = this.getProblemsCaptacion(true);
        let problem = problems.find(p => p.key == param);

        return problem;
    }

    getProblemsPublicacionKyero(all = false) {

        let problems = [
            {
                key: 'precio',
                nombre: 'Precio',
                msg: 'No se ha indicado precio',
                ayuda: 'Es necesario indicar un precio',
                status: 0,
            },
            {
                key: 'localidad',
                nombre: 'Localidad',
                msg: 'No se ha indicado localidad',
                ayuda: 'Es necesario indicar una localidad',
                status: 0,
            },
            {
                key: 'provincia',
                nombre: 'Provincia',
                msg: 'No se ha indicado provincia',
                ayuda: 'Es necesario indicar una provincia',
                status: 0,
            },
            {
                key: 'banos',
                nombre: 'Número de baños',
                msg: 'No se ha indicado número de baños',
                ayuda: 'Es necesario indicar el número de baños',
                status: 0,
            },
            {
                key: 'dormitorios',
                nombre: 'Número de dormitorios',
                msg: 'No se ha indicado número de dormitorios',
                ayuda: 'Es necesario indicar el número de dormitorios',
                status: 0,
            },
        ]

        if (!this.precio_actual) {
            problems = problems.map(p => p.key === 'precio' ? { ...p, status: 1 } : p);
        }

        if (!this.localidad) {
            problems = problems.map(p => p.key === 'localidad' ? { ...p, status: 1 } : p);
        }

        if (!this.provincia) {
            problems = problems.map(p => p.key === 'provincia' ? { ...p, status: 1 } : p);
        }

        if (!this.banos) {
            problems = problems.map(p => p.key === 'banos' ? { ...p, status: 1 } : p);
        }

        if (!this.dormitorios) {
            problems = problems.map(p => p.key === 'dormitorios' ? { ...p, status: 1 } : p);
        }

        return problems;
    }

    getProblemsPublicacionCocampo(all = false) {

        let problems = [
            {
                key: 'precio',
                nombre: 'Precio',
                msg: 'No se ha indicado precio',
                ayuda: 'Es necesario indicar un precio',
                status: 0,
            },
            {
                key: 'localidad',
                nombre: 'Localidad',
                msg: 'No se ha indicado localidad',
                ayuda: 'Es necesario indicar una localidad',
                status: 0,
            },
            {
                key: 'provincia',
                nombre: 'Provincia',
                msg: 'No se ha indicado provincia',
                ayuda: 'Es necesario indicar una provincia',
                status: 0,
            },
            {
                key: 'banos',
                nombre: 'Número de baños',
                msg: 'No se ha indicado número de baños',
                ayuda: 'Es necesario indicar el número de baños',
                status: 0,
            },
            {
                key: 'dormitorios',
                nombre: 'Número de dormitorios',
                msg: 'No se ha indicado número de dormitorios',
                ayuda: 'Es necesario indicar el número de dormitorios',
                status: 0,
            },
        ]

        if (!this.precio_actual) {
            problems = problems.map(p => p.key === 'precio' ? { ...p, status: 1 } : p);
        }

        if (!this.localidad) {
            problems = problems.map(p => p.key === 'localidad' ? { ...p, status: 1 } : p);
        }

        if (!this.provincia) {
            problems = problems.map(p => p.key === 'provincia' ? { ...p, status: 1 } : p);
        }

        if (!this.banos) {
            problems = problems.map(p => p.key === 'banos' ? { ...p, status: 1 } : p);
        }

        if (!this.dormitorios) {
            problems = problems.map(p => p.key === 'dormitorios' ? { ...p, status: 1 } : p);
        }

        return problems;
    }

    getProblemsPublicacionIdealista(all = false) {

        let problems = [
            {
                key: 'precio',
                nombre: 'Precio',
                msg: 'No se ha indicado precio',
                ayuda: 'Es necesario indicar un precio',
                status: 0,
            },
            {
                key: 'localizacion',
                nombre: 'Coordenadas GPS',
                msg: 'No se ha indicado una localización',
                ayuda: 'Es necesario indicar las coordenadas GPS de la propiedad',
                status: 0,
            }
        ]

        if (!this.precio_actual) {
            problems = problems.map(p => p.key === 'precio' ? { ...p, status: 1 } : p);
        }

        // Si no existe el objeto categoria_propiedad hay que dar un error diferente porque significa que la entidad viene sin ese dato (no que no lo tenga cubierto en la ficha)
        if (!this.categoria_propiedad) {

            problems.push({

                key: 'categoria_propiedad',
                nombre: 'Categoría de propiedad',
                msg: 'Hay un error de coherencia de datos',
                ayuda: 'Es necesario corregir el error de coherencia de datos',
                status: 1,
            });
        } else {

            // Si es un terreno es obligatorio que tenga la superficie total, en el resto de casos hace falta la superficie construida
            if (this.categoria_propiedad.id == 7) {

                problems.push({

                    key: 'superficie_total',
                    nombre: 'Superficie total',
                    msg: 'No se ha indicado una superficie total',
                    ayuda: 'Es necesario indicar una superficie total',
                    status: 0,
                });

                if (!this.superficie_total) {
                    problems = problems.map(p => p.key === 'superficie_total' ? { ...p, status: 1 } : p);
                }
            } else {

                problems.push({

                    key: 'superficie',
                    nombre: 'Superficie construída',
                    msg: 'No se ha indicado una superficie construída',
                    ayuda: 'Es necesario indicar una superficie construída',
                    status: 0,
                });

                if (!this.superficie_construida) {
                    problems = problems.map(p => p.key === 'superficie' ? { ...p, status: 1 } : p);
                }
            }

            // El resto de categorías, de la 1 a la 5, son viviendas y necesitan baños y dormitorios
            if (this.categoria_propiedad.id >= 1 && this.categoria_propiedad.id <= 5) {

                problems.push({

                    key: 'banos',
                    nombre: 'Número de baños',
                    msg: 'No se ha indicado número de baños',
                    ayuda: 'Es necesario indicar el número de baños',
                    status: 0,
                });

                problems.push({

                    key: 'dormitorios',
                    nombre: 'Número de dormitorios',
                    msg: 'No se ha indicado número de dormitorios',
                    ayuda: 'Es necesario indicar el número de dormitorios',
                    status: 0,
                });

                if (!this.banos) {
                    problems = problems.map(p => p.key === 'banos' ? { ...p, status: 1 } : p);
                }

                if (!this.dormitorios) {
                    problems = problems.map(p => p.key === 'dormitorios' ? { ...p, status: 1 } : p);
                }
            }
        }

        if (!(this.lat && this.lon)) {
            problems = problems.map(p => p.key === 'localizacion' ? { ...p, status: 1 } : p);
        }

        return problems;
    }

    getProblemsPublicacionThinkspain(all = false) {

        let problems = [
            {
                key: 'precio',
                nombre: 'Precio',
                msg: 'No se ha indicado precio',
                ayuda: 'Es necesario indicar un precio',
                status: 0,
            },
            {
                key: 'localidad',
                nombre: 'Localidad',
                msg: 'No se ha indicado localidad',
                ayuda: 'Es necesario indicar una localidad',
                status: 0,
            },
            {
                key: 'provincia',
                nombre: 'Provincia',
                msg: 'No se ha indicado provincia',
                ayuda: 'Es necesario indicar una provincia',
                status: 0,
            },
            {
                key: 'banos',
                nombre: 'Número de baños',
                msg: 'No se ha indicado número de baños',
                ayuda: 'Es necesario indicar el número de baños',
                status: 0,
            },
            {
                key: 'dormitorios',
                nombre: 'Número de dormitorios',
                msg: 'No se ha indicado número de dormitorios',
                ayuda: 'Es necesario indicar el número de dormitorios',
                status: 0,
            },
        ]

        if (!this.precio_actual) {
            problems = problems.map(p => p.key === 'precio' ? { ...p, status: 1 } : p);
        }

        if (!this.localidad) {
            problems = problems.map(p => p.key === 'localidad' ? { ...p, status: 1 } : p);
        }

        if (!this.provincia) {
            problems = problems.map(p => p.key === 'provincia' ? { ...p, status: 1 } : p);
        }

        if (!this.banos) {
            problems = problems.map(p => p.key === 'banos' ? { ...p, status: 1 } : p);
        }

        if (!this.dormitorios) {
            problems = problems.map(p => p.key === 'dormitorios' ? { ...p, status: 1 } : p);
        }

        return problems;
    }

    getProblemsPublicacionSpainHouses(all = false) {

        let problems = [
            {
                key: 'precio',
                nombre: 'Precio',
                msg: 'No se ha indicado precio',
                ayuda: 'Es necesario indicar un precio',
                status: 0,
            },
            {
                key: 'localidad',
                nombre: 'Localidad',
                msg: 'No se ha indicado localidad',
                ayuda: 'Es necesario indicar una localidad',
                status: 0,
            },
            {
                key: 'provincia',
                nombre: 'Provincia',
                msg: 'No se ha indicado provincia',
                ayuda: 'Es necesario indicar una provincia',
                status: 0,
            },
            {
                key: 'geolocalizacion',
                nombre: 'Geolocalización',
                msg: 'No se ha indicado la geolocalización',
                ayuda: 'Es necesario indicar la geolocalización de la propiedad',
                status: 0
            },
            {
                key: 'direccion',
                nombre: 'Dirección',
                msg: 'No se ha indicado la dirección',
                ayuda: 'Es necesario indicar la dirección de la propiedad',
                status: 0
            }
        ]

        if (!this.precio_actual) {
            problems = problems.map(p => p.key === 'precio' ? { ...p, status: 1 } : p);
        }

        if (!this.localidad) {
            problems = problems.map(p => p.key === 'localidad' ? { ...p, status: 1 } : p);
        }

        if (!this.provincia) {
            problems = problems.map(p => p.key === 'provincia' ? { ...p, status: 1 } : p);
        }

        if (!(this.lat && this.lon)) {
            problems = problems.map(p => p.key === 'geolocalizacion' ? { ...p, status: 1 } : p);
        }

        if (!this.direccion) {
            problems = problems.map(p => p.key === 'direccion' ? { ...p, status: 1 } : p);
        }

        return problems;
    }

    getProblemsPublicacionFotocasa(all = false) {

        let problems = [
            {
                key: 'precio',
                nombre: 'Precio',
                msg: 'No se ha indicado precio',
                ayuda: 'Es necesario indicar un precio',
                status: 0,
            },
            {
                key: 'direccion',
                nombre: 'Dirección',
                msg: 'No se ha indicado dirección',
                ayuda: 'Es necesario indicar una dirección',
                status: 0,
            },
            {
                key: 'localizacion',
                nombre: 'Coordenadas GPS',
                msg: 'No se ha indicado una localización',
                ayuda: 'Es necesario indicar las coordenadas GPS de la propiedad',
                status: 0,
            },
            {
                key: 'certificado_energetico',
                nombre: 'Certificado energético',
                msg: 'No se ha indicado un certificado energético',
                ayuda: 'Es necesario disponer de un certificado energético',
                status: 0,
            }
        ]

        if (!this.precio_actual) {
            problems = problems.map(p => p.key === 'precio' ? { ...p, status: 1 } : p);
        }

        if (!this.direccion) {
            problems = problems.map(p => p.key === 'direccion' ? { ...p, status: 1 } : p);
        }

        if (!(this.lat && this.lon)) {
            problems = problems.map(p => p.key === 'localizacion' ? { ...p, status: 1 } : p);
        }

        if (this.certificado_energetico_status) {

            if (this.certificado_energetico_status == 'no disponible') {

                problems = problems.map(p => p.key === 'certificado_energetico' ? { ...p, status: 1 } : p);
            }

        } else {

            problems = problems.map(p => p.key === 'certificado_energetico' ? { ...p, status: 1 } : p);
        }

        return problems;
    }

    getProblemsVenta(all = false) {

        let problems = [
            {
                key: 'arras',
                nombre: 'Arras',
                msg: 'No hay arras',
                ayuda: 'Es necesario subir arras',
                status: 0,
            },
            {
                key: 'firma_arras',
                nombre: 'Fecha firma arras',
                msg: 'No hay fecha de firma de arras',
                ayuda: 'Es necesario indicar la fecha de firma',
                status: 0,
            },
            {
                key: 'justificante_arras',
                nombre: 'Justificante arras',
                msg: 'No hay justificante de arras',
                ayuda: 'Es necesario subir justificante de arras',
                status: 0,
            },
            {
                key: 'importe_arras',
                nombre: 'Importe arras',
                msg: 'No hay importe de arras incompleto',
                ayuda: 'Es necesario indicar importe de arras',
                status: 0,
            },
            {
                key: 'ibi',
                nombre: 'IBI',
                msg: 'No hay IBI',
                ayuda: 'Es necesario subir IBI',
                status: 0,
            },
            {
                key: 'certificado_energetico',
                nombre: 'Cert. energético',
                msg: 'No hay certificado energético',
                ayuda: 'Es necesario subir certificado energético',
                status: 0,
            },
            {
                key: 'propietarios',
                nombre: 'Propietarios',
                msg: 'No hay propietarios',
                ayuda: 'Es necesario indicar al menos un propietario',
                status: 0,
            }
        ];


        if (!this.getArras()) {
            problems = problems.map(p =>   p.key === 'arras' ? { ...p, status: 2 } : p );
            problems = problems.filter(p => p.key !== 'firma_arras' && p.key !== 'justificante_arras' && p.key !== 'importe_arras');
        }
        else {
            if (!this.firma_arras) { // isNaN wont accept a date in typescript, use date.getTime() instead to produce a number
                problems = problems.map(p =>   p.key === 'firma_arras' ? { ...p, status: 1 } : p );
            }
            if (!this.getJustificanteArras()) {
                problems = problems.map(p =>   p.key === 'justificante_arras' ? { ...p, status: 1 } : p );
            }
            if (!this.importe_arras) {
                problems = problems.map(p =>   p.key === 'importe_arras' ? { ...p, status: 1 } : p );
            }
        }
        if (!this.getIbi()) {
            problems = problems.map(p =>   p.key === 'ibi' ? { ...p, status: 1 } : p );
        }
        if (!this.getCertificacionEnergetica()) {
            problems = problems.map(p =>   p.key === 'certificado_energetico' ? { ...p, status: 1 } : p );
        }
        const propietarios = this.getPropietarios();
        if (propietarios.length == 0) {
            problems = problems.map(p =>   p.key === 'propietarios' ? { ...p, status: 1 } : p );
        }

        if (!all) {
            let problems_return = problems.filter(p => p.status);
            return problems_return;
        }
        return problems;
    }
    hasProblemVenta(param) {
        let problems = this.getProblemsVenta(true);
        let problem = problems.find(p => p.key == param);

        return problem.status;
    }
    getProblemVenta(param) {
        let problems = this.getProblemsVenta(true);
        let problem = problems.find(p => p.key == param);

        return problem;
    }

    isCaptacionDestada(user_id: number) {
        if (!this.captaciones_destacadas) {
            return false;
        }
        for (let i = 0; i < this.captaciones_destacadas.length; i++) {
            if (this.captaciones_destacadas[i].usuario_id == user_id) {
                return true;
            }
        }
        return false;
    }


    getPosition() {

        if (this.lat && this.lon) {
            return {
                position: {
                    lat: this.lat,
                    lng: this.lon
                }
            }
        }
        return {
            position: {
                lat: 0,
                lng: 0
            }
        }
    }

    getInteresados() {
        if (this.relaciones && this.relaciones.constructor === Array && this.relaciones.length > 0) {
            const interesados = this.relaciones.filter(elem => elem.tipo_relacion_id == 3); //2 = otro 3 = interesado 1 = propietario

            return interesados.sort((a, b) => (a.fecha_desde < b.fecha_desde) ? 1 : ((b.fecha_desde < a.fecha_desde) ? -1 : 0));

        }
        return [];
    }
    getInteresadosActivos() {
        if (this.relaciones && this.relaciones.constructor === Array && this.relaciones.length > 0) {
            const interesados = this.relaciones.filter(elem => elem.tipo_relacion_id == 3 && elem.fecha_hasta === null);
            return interesados.sort((a, b) => (a.fecha_desde < b.fecha_desde) ? 1 : ((b.fecha_desde < a.fecha_desde) ? -1 : 0));

        }
        return [];
    }
    getInteresadosCaducados() {
        if (this.relaciones && this.relaciones.constructor === Array && this.relaciones.length > 0) {
            const interesados = this.relaciones.filter(elem => elem.tipo_relacion_id == 3 && elem.fecha_hasta != null);
            return interesados.sort((a, b) => (a.fecha_hasta < b.fecha_hasta) ? 1 : ((b.fecha_hasta < a.fecha_hasta) ? -1 : 0));
        }
        return [];
    }
    getInteresadosEnOportunidad() {
        if (this.relaciones && this.relaciones.constructor === Array && this.relaciones.length > 0) {
            const interesados = this.relaciones.filter(elem => elem.tipo_relacion_id == 3 && elem.oportunidad_id != null);
            return interesados.sort((a, b) => (a.fecha_desde < b.fecha_desde) ? 1 : ((b.fecha_desde < a.fecha_desde) ? -1 : 0));
        }
        return [];
    }
    getInteresadosEnOportunidadActiva() { //no comprobamos oportunidad.closed, con el fecha_hasta es suficiente
        if (this.relaciones && this.relaciones.constructor === Array && this.relaciones.length > 0) {
            const interesados = this.relaciones.filter(elem => elem.tipo_relacion_id == 3 && elem.oportunidad_id != null && elem.fecha_hasta == null);
            return interesados.sort((a, b) => (a.fecha_desde < b.fecha_desde) ? 1 : ((b.fecha_desde < a.fecha_desde) ? -1 : 0));
        }
        return [];
    }

    getPropietarios() {

        if (this.propietarios) {
            return this.propietarios;
        }

        if (this.relaciones && this.relaciones.constructor === Array && this.relaciones.length > 0) {
            const interesados = this.relaciones.filter(elem => elem.tipo_relacion_id == 1); //2 = otro 3 = interesado 1 = propietario
            return interesados;
        }
        return [];
    }
    getCompradores() {

        if (this.compradores) {
            return this.compradores;
        }

        if (this.relaciones && this.relaciones.constructor === Array && this.relaciones.length > 0) {
            const interesados = this.relaciones.filter(elem => elem.tipo_relacion_id == 4); //2 = otro 3 = interesado 1 = propietario
            return interesados;
        }
        return [];
    }

    getVinculados() {
        if (this.relaciones && this.relaciones.constructor === Array && this.relaciones.length > 0) {
            const interesados = this.relaciones.filter(elem => elem.tipo_relacion_id == 2); //2 = otro 3 = interesado 1 = propietario
            return interesados;
        }
        return [];
    }

    getPropietariosYVinculados() {
        if (this.relaciones && this.relaciones.constructor === Array && this.relaciones.length > 0) {
            const interesados = this.relaciones.filter(elem => (elem.tipo_relacion_id == 2 || elem.tipo_relacion_id == 1)); //2 = otro 3 = interesado 1 = propietario
            return interesados;
        }
        return [];
    }
    getPropietariosYVinculadosActivos() {
        if (this.relaciones && this.relaciones.constructor === Array && this.relaciones.length > 0) {
            const interesados = this.relaciones.filter(elem => ((elem.tipo_relacion_id == 2 || elem.tipo_relacion_id == 1) && elem.fecha_hasta == null)); //2 = otro 3 = interesado 1 = propietario
            return interesados;
        }
        return [];
    }

    getUltimoPrecioNegociado() {
        if (this.precios && this.precios.constructor === Array && this.precios.length > 0) {
            const ultimoPrecioNegociado = this.precios
                .filter(precio => precio.oportunidad_id != null)
                .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                .shift();
            return ultimoPrecioNegociado;
        }
        return null;
    }

    getTareasActivas() {
        //Devolvemos las actividades con tipo tarea que no se han completado aun
        if (this.tareas && this.tareas.constructor === Array) {
            const tareas = this.tareas.filter(elem => elem.tipo == 'tarea' && !elem.completada);
            return tareas;
        }
        return [];
    }
    getProximasCitasCaptacion() {
        //Devolvemos las actividades con tipo tarea que no se han completado aun
        if (this.citas_captacion && this.citas_captacion.constructor === Array) {
            const citas = this.citas_captacion.filter(elem => elem.tipo == 'reunion_captacion' && new Date(elem.fecha) > new Date());
            return citas;
        }
        return [];
    }

    getKilometrosCaptacion() {

        if (this.citas && this.citas.length > 0) {
            const citas = this.citas.filter(elem => elem.tipo == 'reunion_captacion' && new Date(elem.fecha) <= new Date());

            // El método reduce itera sobre el array y acumula un valor
            // En este caso suma los kilómetros de todas las citas
            // El primer parámetro (total) es el acumulador que inicia en 0
            // El segundo (cita) es cada elemento del array
            const kilometros = citas.reduce((total, cita) => {
                if (cita.gasto_gestion && cita.gasto_gestion.kilometros) {
                    return total + cita.gasto_gestion.kilometros;
                }
                return total;
            }, 0);
            return kilometros;
        }
        return 0;
    }

    getKilometrosVenta() {

        if (this.citas && this.citas.length > 0) {
            const citas = this.citas.filter(elem => elem.tipo == 'reunion_venta' && new Date(elem.fecha) <= new Date());

            // El método reduce itera sobre el array y acumula un valor
            // En este caso suma los kilómetros de todas las citas
            // El primer parámetro (total) es el acumulador que inicia en 0
            // El segundo (cita) es cada elemento del array
            const kilometros = citas.reduce((total, cita) => {
                if (cita.gasto_gestion && cita.gasto_gestion.kilometros) {
                    return total + cita.gasto_gestion.kilometros;
                }
                return total;
            }, 0);
            return kilometros;
        }
        return 0;
    }

    getImporteCaptacion() {

        if (this.citas && this.citas.length > 0) {
            const citas = this.citas.filter(elem => elem.tipo == 'reunion_captacion' && new Date(elem.fecha) <= new Date());

            // El método reduce itera sobre el array y acumula un valor
            // En este caso suma los kilómetros de todas las citas
            // El primer parámetro (total) es el acumulador que inicia en 0
            // El segundo (cita) es cada elemento del array
            const importe = citas.reduce((total, cita) => {
                if (cita.gasto_gestion && cita.gasto_gestion.importe) {
                    return total + cita.gasto_gestion.importe;
                }
                return total;
            }, 0);
            return importe;
        }
        return 0;
    }

    getImporteVenta() {

        if (this.citas && this.citas.length > 0) {
            const citas = this.citas.filter(elem => elem.tipo == 'reunion_venta' && new Date(elem.fecha) <= new Date());

            // El método reduce itera sobre el array y acumula un valor
            // En este caso suma los kilómetros de todas las citas
            // El primer parámetro (total) es el acumulador que inicia en 0
            // El segundo (cita) es cada elemento del array
            const importe = citas.reduce((total, cita) => {
                if (cita.gasto_gestion && cita.gasto_gestion.importe) {
                    return total + cita.gasto_gestion.importe;
                }
                return total;
            }, 0);
            return importe;
        }
        return 0;
    }

    checkPasarSiguienteEstado() {

        if (this.estado_captacion === 'captacion_pendiente') {
            return this.captacion_fotos && this.captacion_refcatastral;
        }

        if (this.estado_captacion === 'captacion_valoracion') {
            return this.captacion_valoracion && this.captacion_condiciones_aceptadas;
        }

        if (this.estado_captacion === 'captacion_preparacion') {
            return this.getProblemsCaptacion().length == 0;
        }

        return true;
    }

    getPorcentajeCaptacion() {

        let porcentaje = 0;

        if (this.estado_captacion === 'captacion_pendiente') {

            if (this.captacion_fotos) porcentaje += 50;
            if (this.captacion_refcatastral) porcentaje += 50;
            return porcentaje + '%';
        }

        if (this.estado_captacion === 'captacion_valoracion') {
            if (this.captacion_valoracion) porcentaje += 50;
            if (this.captacion_condiciones_aceptadas) porcentaje += 50;
            return porcentaje + '%';
        }

        if (this.estado_captacion === 'captacion_preparacion') {    

            const problems = this.getProblemsCaptacion(true);
            const totalProblems = problems.length;
            const solvedProblems = problems.filter(p => !p.status).length;
            return Math.round((solvedProblems / totalProblems) * 100) + '%';
        }

        if (this.estado_captacion === 'captacion_revision') {
            return "pendiente";
        }

        return 0;
    }


}