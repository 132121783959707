import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {

        //Si hay un error de login (401) no hacemos nada
        if (err.status === 401 && request.url.includes('login')) {
          return throwError(err); // Pass the error along without handling
        }

        //Si hay un problema de permisos, intentamos refrescar la sesion (los tokens) usando el token_refresh
        //Valorar si retirar el 403
        if ([401, 403].indexOf(err.status) !== -1) {

          // Para no entrar en bucle infinito no refrescamos si ya estamos en la página de refresco
          if (request.url.includes('refresh-session')) {
            console.log('Error Interceptor: Logout al recibir 401 al intentar refrescar la sesión');
            this._authenticationService.logout();
            this._router.navigate(['/pages/authentication/login']);
            err.error.message = 'La sesión ha caducado o no tienes permisos para realizar esta acción.';
            return throwError(err.error);
          }

          // Si no estamos en la página de refresco, intentamos refrescar la sesión
          return this.handleAuthError(request, next);

        }

        //Intento de pasar los errores en bonitos hasta toast
        if (err.error.hasOwnProperty('errors')) {

          var message = err.error.message;
          const errors = err.error.errors;

          // trae errores de multiples entidades
          if (errors && errors.constructor === Array) {
            for (var num in errors) {
              for (var field in errors[num]) {
                var fieldError = errors[num][field];
                message += '<br><strong>' + (parseInt(num) + 1) + '</strong> ' + field + '<br>';
                for (var valErrors in errors[num][field]) {
                  message += '--' + fieldError[valErrors] + '<br>';
                }
              }
            }
          }
          else {
            for (var field in errors) {

              //related models
              if (errors[field].constructor === Array) {
                for (var num in errors[field]) {
                  for (var related_field in errors[field][num]) {
                    var fieldError = errors[field][num][related_field];
                    message += '<br><strong>' + (parseInt(num) + 1) + '</strong> ' + related_field + '<br>';
                    for (var valErrors in errors[field][num][related_field]) {
                      message += '--' + fieldError[valErrors] + '<br>';
                    }
                  }

                }
              }
              else {
                var fieldError = errors[field];
                message += '<br>' + field + '<br>';
                for (var valErrors in errors[field]) {
                  message += '--' + fieldError[valErrors] + '<br>';
                }
              }

            }
          }
        }

        err.error.message = message;

        const error = err.error.message || err.statusText;
        return throwError(err.error);
      })
    );
  }



  private handleAuthError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._authenticationService.refreshTokens().pipe(
      switchMap((response: any) => {

        // Actualizamos los tokens
        this._authenticationService.setAccessToken(response.token);
        this._authenticationService.setRefreshToken(response.token_refresh);

        // Clonamos la solicitud original y agregamos el nuevo token de acceso
        const newRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${response.token}`
          }
        });

        // Reintentamos la solicitud original con el nuevo token
        return next.handle(newRequest);
      }),
      catchError((error) => {
        // Si falla la renovación de tokens, puedes redirigir al usuario al inicio de sesión
        // o realizar alguna otra acción apropiada.
        // Por ejemplo: this.authService.logout();
        console.log('Error Interceptor: Logout al fallar el refresco de tokens');
        this._authenticationService.logout();
        this._router.navigate(['/pages/authentication/login']);

        return throwError(error);
      })
    );
  }

}
