import { Imagen } from "./imagen.model"
import { Type } from "class-transformer";

export interface IBannerTranslation {
    id: number,
    locale: string,
    titulo: string,
    subtitulo: string,
    descripcion: string,
    nombre_boton: string,
    url: string
}

export interface IBanner {
    id: number,
    created: Date,
    modified: Date,
    slideshow_id: number,
    slug: string,
    titulo: string,
    descripcion: string,
    requerimientos: string,
    imagen: Imagen,
    _translations: IBannerTranslation[]
}

export class Banner implements IBanner {
    public id: number;
    public created: Date;
    public modified: Date;
    public slideshow_id: number;
    public slug: string;
    public titulo: string;
    public subtitulo: string;
    public descripcion: string;
    public nombre_boton: string;
    public requerimientos: string;
    @Type(() => Imagen)
    public imagen: Imagen;
    public _translations: IBannerTranslation[];

    static get modelName() {
        return 'Banner'
    }
}