<div class="card">
    <div class="card-header d-block">
        <h4 class="card-title">Caducidad de contratos</h4>
        <h6 class="card-subtitle text-muted">Contratos vencidos o que vencen en los próximos {{dias}} días</h6>
    </div>
    <div class="card-body pb-1">
        <div *ngIf="propiedades.length > 0" class="mb-1">
            <div class="tabla-propiedades">
                <div class="row cabecera align-middle">
                    <div class="col-1 text-center">
                        ID
                    </div>
                    <div class="col text-center">
                        ESTADO
                    </div>
                    <div class="col-6">
                        NOMBRE
                    </div>
                    <div class="col text-center">
                        VENCIMIENTO
                    </div>
                </div>
                <div class="row fila" *ngFor="let propiedad of propiedades" (click)="navegar(propiedad.id)" style="cursor: pointer">
                    <div class="col-1 text-center">{{propiedad.id}}</div>
                    <div class="col text-center">
                        <span [innerHtml]="propiedad.getBadgeEstado()"></span>
                    </div>
                    <div class="col-6 text-truncate">
                        <span *ngIf="propiedad.vencido" class="badge badge-pill badge-light-danger">Vencido</span>
                        {{propiedad.nombre}}
                    </div>
                    <div class="col text-center">
                        {{propiedad.vence_contrato | date: 'd/MM/YYYY'}}
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="propiedades.length == 0" class="mb-1">
            No hay propiedades con contratos próximos a vencer
        </div>
    </div>
</div>

