<div class="card">
    <div class="card-header">
        <h4 class="card-title">Próximas citas</h4>
        <h4 class="card-title">
          <a [routerLink]="['/actividades/citas']" class="btn btn-sm btn-success">
            Gestionar <span [data-feather]="'calendar'" [class]="'mr-50'"></span>
          </a>
        </h4>
      </div>
      <div class="card-body pb-1">
        <full-calendar #calendar [options]="calendarOptions">
          <ng-template #eventContent let-arg>

              <div *ngIf="arg.view.type === 'listMonth'" class="ev evListMonth container">

                <div class="row">
                  <div class="col">
                    <p  class="text-truncate mb-50" style="max-width: 300px">
                      <span *ngIf="arg.view.type === 'listMonth'" class="mr-25" [innerHTML]="arg.event.extendedProps.cita.getBadgeTipoReunion()"></span>
                      <b>{{ arg.event.title }}</b>
                    </p>

                    <p *ngIf="arg.event.extendedProps.contactos.length > 0" class="mt-25 mb-25">
                      <a style="color: #668d22" [routerLink]="['/contactos',contacto.id]" *ngFor="let contacto of arg.event.extendedProps.contactos; let i = index; let isLast = last">
                        {{contacto.nombre}}{{isLast ? '' : (i < arg.event.extendedProps.contactos.length - 2) ? ', ' : ' y '}}
                      </a>
                    </p>
                    <p class="mt-25" *ngIf="arg.event.extendedProps.propiedades.length > 0">
                      <a *ngFor="let propiedad of arg.event.extendedProps.propiedades; let i = index; let isLast = last" [routerLink]="['/inventario/propiedades',propiedad.id]" class="ml-0 mr-25 badge badge-primary text-white">
                        {{propiedad.id}}
                      </a>
                    </p>
                  </div>
                  <div class="col-auto">
                    <div *ngIf="arg.event.extendedProps.usuarios.length > 0" class="avatar-group jusstify-content-end">
                      <div placement="bottom" class="avatar pull-up"  *ngFor="let user of arg.event.extendedProps.usuarios">
                        <ngx-avatar size="30" src="{{user.imagen ? user.imagen.url_small():''}}" [name]="user.getFullName()"></ngx-avatar>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </ng-template>


        </full-calendar>
    </div>
</div>


