import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MentionModule } from 'angular-mentions';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AvatarModule } from 'ngx-avatar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { PropiedadesAlertComponent } from './propiedades-alert.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    PropiedadesAlertComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule, 
    MentionModule,
    PerfectScrollbarModule,
    AvatarModule,
    NgxDatatableModule
  ],
  exports: [
    PropiedadesAlertComponent
  ]
})
export class PropiedadesAlertModule {}
