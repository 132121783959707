import { Landing } from "./landing.model";
import { Type } from 'class-transformer';

export interface IEstadoPublicacionLanding {
    id: number,
    created: Date,
    modified: Date,
    cms_propiedad_id: number,
    landing_id: number,
    fecha: Date,
    estado: string,
    landing: Landing,
}

export class EstadoPublicacionLanding implements IEstadoPublicacionLanding {
    public id: number;
    public created: Date;
    public modified: Date;
    public cms_propiedad_id: number;
    public landing_id: number;
    public fecha: Date;
    public estado: string;
    @Type(() => Landing)
    public landing: Landing;
}