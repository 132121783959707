
import { Type } from 'class-transformer';
import { Propiedad } from './propiedad.model';
import { Partner } from './partner.model';


export interface IPartnerPropiedad {
    id: number,
    created: Date,
    modified: Date,
    partner_id: number,
    propiedad_id: number,
    comision: number,
    propiedad: Propiedad,
    partner: Partner
}

export class PartnerPropiedad implements IPartnerPropiedad {
    public id: number;
    public created: Date;
    public modified: Date;
    public partner_id: number;
    public propiedad_id: number;
    public comision: number;
    @Type(() => Propiedad)
    public propiedad: Propiedad;
    @Type(() => Partner)
    public partner: Partner;

    static get modelName() {
        return 'PartnerPropiedad'
    }
}