import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  //public
  public isLoading: Observable<boolean>;
  //private
  private currentIsLoadingSubject: BehaviorSubject<boolean>;

  constructor(
  ) {
    this.currentIsLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading = this.currentIsLoadingSubject.asObservable();
  }


  startLoading() {
    this.currentIsLoadingSubject.next(true);
  }

  stopLoading() {
    this.currentIsLoadingSubject.next(false);
  }

  isLoadingActive(): boolean {
    return this.currentIsLoadingSubject.value;
  }

}