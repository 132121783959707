import { EstadoPublicacionPortal } from "./estado-publicacion-portal.model";
import { Imagen } from "./imagen.model";
import { Type } from 'class-transformer';

export interface IPortal {
    id: number,
    created: Date,
    modified: Date,
    nombre: string,
    caracteristicas: string,
    activo: boolean,
    icono: Imagen,
    estados_publicaciones_portales: EstadoPublicacionPortal[]
    url: String
}

export class Portal implements IPortal {
    public id: number;
    public created: Date;
    public modified: Date;
    public nombre: string;
    public caracteristicas: string;
    public activo: boolean;
    @Type(() => Imagen)
    public icono: Imagen;
    @Type(() => EstadoPublicacionPortal)
    public estados_publicaciones_portales: EstadoPublicacionPortal[];
    public url: string;


    getIcono() {

        return this.icono;
    }    
}

