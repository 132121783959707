import { Component, OnInit } from '@angular/core'
import { Contacto, Propiedad } from 'app/models';
import { CacheService } from 'app/services';
import { forkJoin, Subject } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public contentHeader: object
  public propiedades: Propiedad[] = [];
  public contactos: Contacto[] = [];

  constructor (
    private cacheSvc: CacheService,
  ) {
  }


  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          }
        ]
      }
    }

    forkJoin({
      res_contactos: this.cacheSvc.getContactosList(),
      res_propiedades: this.cacheSvc.getPropiedadesList(),
    }).subscribe({
      next:  ({res_contactos, res_propiedades}) => {
        this.contactos = res_contactos;
        this.propiedades = res_propiedades;
       },
      error: err => {
        console.log(err);
      }
    });

  }

  countByStatus(status: string): number {
    return this.propiedades.filter(p => p.estado === status).length;
  }

}
