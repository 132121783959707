import { ImagenPublica } from "./imagen-publica.model"
import { Type } from "class-transformer";
import { Propiedad } from "./propiedad.model";
import { PartnerPropiedad } from "./partner-propiedad.model";
import { Usuario } from "./usuario.model";

export interface IPartner {
    id: number,
    nombre: string,
    direccion: string,
    localidad: string,
    pais: string,
    provincia: string,
    cp: string,
    comision_general: number,
    es_empresa: boolean,
    area_influencia: string,
    logo: ImagenPublica,
    propiedades: Propiedad[],
    partners_propiedades: PartnerPropiedad[],
    usuarios: Usuario[]
}

export class Partner implements IPartner {
    id: number;
    nombre: string;
    direccion: string;
    localidad: string;
    pais: string;
    provincia: string;
    cp: string;
    comision_general: number;
    es_empresa: boolean;
    area_influencia: string;
    @Type(() => ImagenPublica)
    public logo: ImagenPublica;
    @Type(() => Propiedad)
    public propiedades: Propiedad[];
    @Type(() => PartnerPropiedad)
    public partners_propiedades: PartnerPropiedad[];
    @Type(() => Usuario)
    public usuarios: Usuario[];

    static get modelName() {
        return 'Partner'
    }
}