import { Banner } from "./banner.model";
import { Type } from "class-transformer";

export interface ISlideshow {
    id: number,
    created: Date,
    modified: Date,
    grupo_id: number,
    slug: string,
    nombre: string,
    banners: Banner[]
}

export class Slideshow implements ISlideshow {
    public id: number;
    public created: Date;
    public modified: Date;
    public grupo_id: number;
    public slug: string;
    public nombre: string;
    @Type(() => Banner)
    public banners: Banner[];

    static get modelName() {
        return 'SlideShow'
    }
}