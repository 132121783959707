import { Imagen } from "./imagen.model";
import { Type } from 'class-transformer';

export interface ICategoriaNoticia {
    id: number,
    created: Date,
    modified: Date,
    slug: string,
    nombre: string,
    titulo: string,
    descripcion: string
}


export interface ISeoNoticiaTranslation {
    id: number,
    locale: string,
    title: string,
    description: string,
    keywords: string
}

export interface ISeoNoticia {
    id: number,
    title: string,
    description: string,
    keywords: string,
    _translations: ISeoNoticiaTranslation[]

}

export interface INoticiaTranslation {
    id: number,
    locale: string,
    slug: string,
    titulo: string,
    subtitulo: string,
    contenido: string
}

export interface INoticia {
    id: number,
    created: Date,
    modified: Date,
    slug: string,
    titulo: string,
    subtitulo: string,
    contenido: string,
    categoria_noticia_id: number,
    usuario_id: number,
    autor: string,
    fecha: Date,
    estado: string,
    tipo: string,
    seo_noticia: ISeoNoticia,
    categoria_noticia: ICategoriaNoticia,
    imagen_destacada: Imagen,
    _translations: INoticiaTranslation[]
}

export class Noticia implements INoticia {
    public id: number;
    public created: Date;
    public modified: Date;
    public slug: string;
    public titulo: string;
    public subtitulo: string;
    public contenido: string;
    public categoria_noticia_id: number;
    public usuario_id: number;
    public autor: string;
    public fecha: Date;
    public estado: string;
    public tipo: string;
    public seo_noticia: ISeoNoticia;
    public categoria_noticia: ICategoriaNoticia;
    @Type(() => Imagen)
    public imagen_destacada: Imagen
    public _translations: INoticiaTranslation[];

    static get modelName() {
        return 'Noticia'
    }
}




