<div class="card">
    <div class="card-header">
        <h4 class="card-title">Tareas pendientes</h4>
        <h4 class="card-title">
            <a [routerLink]="['/actividades/tareas']" class="btn btn-sm btn-success">
              Gestionar <span [data-feather]="'check-square'" [class]="'mr-50'"></span>
            </a>
          </h4>
    </div>
    <div class="card-body pb-1">
        <div class="content-area-wrapper container-xxl p-0" *ngIf="actividades.length > 0">
            <div class="content-right">
                <div class="content-wrapper container-xxl p-0">
                    <div class="content-body">
                        <div class="todo-app-list">
                            <div class="todo-task-list-wrapper list-group" [perfectScrollbar]>
                                <ul class="todo-task-list media-list" id="todo-task-list" >
                                    <tarea-list-item [tarea]="tarea" [es_dashboard]="true" *ngFor="let tarea of actividades; let i = index"></tarea-list-item>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="actividades.length == 0" class="mb-1">
            No hay tareas pendientes
        </div>
    </div>
</div>