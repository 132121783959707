import { Type } from 'class-transformer';
import { Usuario } from "./usuario.model";

export interface ILog {
    id: number,
    created: Date,
    usuario_id: number,
    event: string,
    notification: string,
    mensaje: string,
    notif_link: string,
    ip: string,
    categoria: string,
    model: string,
    foreign_key: number,
    usuario: Usuario
}

export class Log implements ILog {
    public id: number;
    public created: Date;
    public usuario_id: number;
    public event: string;
    public notification: string;
    public mensaje: string;
    public notif_link: string;
    public ip: string;
    public categoria: string;
    public model: string;
    public foreign_key: number;
    @Type(() => Usuario)
    public usuario: Usuario;

    static get modelName() {
        return 'Log'
    }

}