export const MIN_COMISION = 6500;

export const ORIGENES_CAPTACION = [
  { 'nombre': 'Teléfono' },
  { 'nombre': 'Correo electrónico' },
  { 'nombre': 'Redes sociales' },
  { 'nombre': 'Web GCH' },
  { 'nombre': 'Partner' }
]

export const PROVINCIAS = [
  { 'nombre': 'Álava/Araba' },
  { 'nombre': 'Albacete' },
  { 'nombre': 'Alicante' },
  { 'nombre': 'Almería' },
  { 'nombre': 'Asturias' },
  { 'nombre': 'Ávila' },
  { 'nombre': 'Badajoz' },
  { 'nombre': 'Baleares' },
  { 'nombre': 'Barcelona' },
  { 'nombre': 'Burgos' },
  { 'nombre': 'Cáceres' },
  { 'nombre': 'Cádiz' },
  { 'nombre': 'Cantabria' },
  { 'nombre': 'Castellón' },
  { 'nombre': 'Ceuta' },
  { 'nombre': 'Ciudad Real' },
  { 'nombre': 'Córdoba' },
  { 'nombre': 'Cuenca' },
  { 'nombre': 'Girona' },
  { 'nombre': 'Granada' },
  { 'nombre': 'Guadalajara' },
  { 'nombre': 'Gipuzkoa' },
  { 'nombre': 'Huelva' },
  { 'nombre': 'Huesca' },
  { 'nombre': 'Jaén' },
  { 'nombre': 'A Coruña' },
  { 'nombre': 'La Rioja' },
  { 'nombre': 'Las Palmas' },
  { 'nombre': 'León' },
  { 'nombre': 'Lleida' },
  { 'nombre': 'Lugo' },
  { 'nombre': 'Madrid' },
  { 'nombre': 'Málaga' },
  { 'nombre': 'Melilla' },
  { 'nombre': 'Murcia' },
  { 'nombre': 'Navarra' },
  { 'nombre': 'Ourense' },
  { 'nombre': 'Palencia' },
  { 'nombre': 'Pontevedra' },
  { 'nombre': 'Salamanca' },
  { 'nombre': 'Segovia' },
  { 'nombre': 'Sevilla' },
  { 'nombre': 'Soria' },
  { 'nombre': 'Tarragona' },
  { 'nombre': 'Tenerife' },
  { 'nombre': 'Teruel' },
  { 'nombre': 'Toledo' },
  { 'nombre': 'Valencia' },
  { 'nombre': 'Valladolid' },
  { 'nombre': 'Bizkaia' },
  { 'nombre': 'Zamora' },
  { 'nombre': 'Zaragoza' },
]

export const PAISES_PROPIEDADES = [
  {
    "en_nombre": "Spain",
    "nombre": "España"
  },
  {
    "en_nombre": "France",
    "nombre": "Francia"
  },
  {
    "en_nombre": "Portugal",
    "nombre": "Portugal"
  },
]

export const PAISES = [
  {
    "en_nombre": "Afghanistan",
    "nombre": "Afganistán"
  },
  {
    "en_nombre": "Albania",
    "nombre": "Albania"
  },
  {
    "en_nombre": "Algeria",
    "nombre": "Algeria"
  },
  {
    "en_nombre": "American Samoa",
    "nombre": "Samoa Americana"
  },
  {
    "en_nombre": "Andorra",
    "nombre": "Andorra"
  },
  {
    "en_nombre": "Angola",
    "nombre": "Angola"
  },
  {
    "en_nombre": "Anguilla",
    "nombre": "Anguilla"
  },
  {
    "en_nombre": "Antarctica",
    "nombre": "Antártida"
  },
  {
    "en_nombre": "Antigua and Barbuda",
    "nombre": "Antigua y Barbuda"
  },
  {
    "en_nombre": "Argentina",
    "nombre": "Argentina"
  },
  {
    "en_nombre": "Armenia",
    "nombre": "Armenia"
  },
  {
    "en_nombre": "Aruba",
    "nombre": "Aruba"
  },
  {
    "en_nombre": "Australia",
    "nombre": "Australia"
  },
  {
    "en_nombre": "Austria",
    "nombre": "Austria"
  },
  {
    "en_nombre": "Azerbaijan",
    "nombre": "Azerbaiyán"
  },
  {
    "en_nombre": "Bahamas",
    "nombre": "Bahamas"
  },
  {
    "en_nombre": "Bahrain",
    "nombre": "Bahrein"
  },
  {
    "en_nombre": "Bangladesh",
    "nombre": "Bangladesh"
  },
  {
    "en_nombre": "Barbados",
    "nombre": "Barbados"
  },
  {
    "en_nombre": "Belarus",
    "nombre": "Bielorrusia"
  },
  {
    "en_nombre": "Belgium",
    "nombre": "Bélgica"
  },
  {
    "en_nombre": "Belize",
    "nombre": "Belice"
  },
  {
    "en_nombre": "Benin",
    "nombre": "Benín"
  },
  {
    "en_nombre": "Bermuda",
    "nombre": "Bermuda"
  },
  {
    "en_nombre": "Bhutan",
    "nombre": "Bután"
  },
  {
    "en_nombre": "Bolivia",
    "nombre": "Bolivia"
  },
  {
    "en_nombre": "Bosnia and Herzegovina",
    "nombre": "Bosnia-Herzegovina"
  },
  {
    "en_nombre": "Botswana",
    "nombre": "Botswana"
  },
  {
    "en_nombre": "Brazil",
    "nombre": "Brasil"
  },
  {
    "en_nombre": "Brunei Darussalam",
    "nombre": "Brunei"
  },
  {
    "en_nombre": "Bulgaria",
    "nombre": "Bulgaria"
  },
  {
    "en_nombre": "Burkina Faso",
    "nombre": "Burkina Faso"
  },
  {
    "en_nombre": "Burundi",
    "nombre": "Burundi"
  },
  {
    "en_nombre": "Cambodia",
    "nombre": "Camboya"
  },
  {
    "en_nombre": "Cameroon",
    "nombre": "Camerún"
  },
  {
    "en_nombre": "Canada",
    "nombre": "Canadá"
  },
  {
    "en_nombre": "Cape Verde",
    "nombre": "Cabo Verde"
  },
  {
    "en_nombre": "Cayman Islands",
    "nombre": "Islas Caimán"
  },
  {
    "en_nombre": "Central African Republic",
    "nombre": "República Centroafricana"
  },
  {
    "en_nombre": "Chad",
    "nombre": "Chad"
  },
  {
    "en_nombre": "Chile",
    "nombre": "Chile"
  },
  {
    "en_nombre": "China",
    "nombre": "China"
  },
  {
    "en_nombre": "Christmas Island",
    "nombre": "Isla de Navidad"
  },
  {
    "en_nombre": "Cocos (Keeling) Islands",
    "nombre": "Islas Cocos"
  },
  {
    "en_nombre": "Colombia",
    "nombre": "Colombia"
  },
  {
    "en_nombre": "Comoros",
    "nombre": "Comores"
  },
  {
    "en_nombre": "Congo",
    "nombre": "República del Congo"
  },
  {
    "en_nombre": "Congo, The Democratic Republic of the",
    "nombre": "República Democrática del Congo"
  },
  {
    "en_nombre": "Cook Islands",
    "nombre": "Islas Cook"
  },
  {
    "en_nombre": "Costa Rica",
    "nombre": "Costa Rica"
  },
  {
    "en_nombre": "Cote D'Ivoire",
    "nombre": "Costa de Marfíl"
  },
  {
    "en_nombre": "Croatia",
    "nombre": "Croacia"
  },
  {
    "en_nombre": "Cuba",
    "nombre": "Cuba"
  },
  {
    "en_nombre": "Cyprus",
    "nombre": "Chipre"
  },
  {
    "en_nombre": "Czech Republic",
    "nombre": "República Checa"
  },
  {
    "en_nombre": "Denmark",
    "nombre": "Dinamarca"
  },
  {
    "en_nombre": "Djibouti",
    "nombre": "Djibouti"
  },
  {
    "en_nombre": "Dominica",
    "nombre": "Dominica"
  },
  {
    "en_nombre": "Dominican Republic",
    "nombre": "República Dominicana"
  },
  {
    "en_nombre": "Ecuador",
    "nombre": "Ecuador"
  },
  {
    "en_nombre": "Egypt",
    "nombre": "Egipto"
  },
  {
    "en_nombre": "El Salvador",
    "nombre": "El Salvador"
  },
  {
    "en_nombre": "Equatorial Guinea",
    "nombre": "Guinea Ecuatorial"
  },
  {
    "en_nombre": "Eritrea",
    "nombre": "Eritrea"
  },
  {
    "en_nombre": "Estonia",
    "nombre": "Estonia"
  },
  {
    "en_nombre": "Ethiopia",
    "nombre": "Etiopía"
  },
  {
    "en_nombre": "Falkland Islands (Malvinas)",
    "nombre": "Islas Malvinas"
  },
  {
    "en_nombre": "Faroe Islands",
    "nombre": "Islas Feroe"
  },
  {
    "en_nombre": "Fiji",
    "nombre": "Fiji"
  },
  {
    "en_nombre": "Finland",
    "nombre": "Finlandia"
  },
  {
    "en_nombre": "France",
    "nombre": "Francia"
  },
  {
    "en_nombre": "French Guiana",
    "nombre": "Guyana Francesa"
  },
  {
    "en_nombre": "French Polynesia",
    "nombre": "Polinesia Francesa"
  },
  {
    "en_nombre": "French Southern Territories",
    "nombre": "Tierras Australes y Antárticas Francesas"
  },
  {
    "en_nombre": "Gabon",
    "nombre": "Gabón"
  },
  {
    "en_nombre": "Gambia",
    "nombre": "Gambia"
  },
  {
    "en_nombre": "Georgia",
    "nombre": "Georgia"
  },
  {
    "en_nombre": "Germany",
    "nombre": "Alemania"
  },
  {
    "en_nombre": "Ghana",
    "nombre": "Ghana"
  },
  {
    "en_nombre": "Gibraltar",
    "nombre": "Gibraltar"
  },
  {
    "en_nombre": "Greece",
    "nombre": "Grecia"
  },
  {
    "en_nombre": "Greenland",
    "nombre": "Groenlandia"
  },
  {
    "en_nombre": "Grenada",
    "nombre": "Granada"
  },
  {
    "en_nombre": "Guadeloupe",
    "nombre": "Guadalupe"
  },
  {
    "en_nombre": "Guam",
    "nombre": "Guam"
  },
  {
    "en_nombre": "Guatemala",
    "nombre": "Guatemala"
  },
  {
    "en_nombre": "Guinea",
    "nombre": "Guinea"
  },
  {
    "en_nombre": "Guinea-Bissau",
    "nombre": "Guinea-Bissau"
  },
  {
    "en_nombre": "Guyana",
    "nombre": "Guyana"
  },
  {
    "en_nombre": "Haiti",
    "nombre": "Haití"
  },
  {
    "en_nombre": "Holy See (Vatican City State)",
    "nombre": "Vaticano"
  },
  {
    "en_nombre": "Honduras",
    "nombre": "Honduras"
  },
  {
    "en_nombre": "Hong Kong",
    "nombre": "Hong Kong"
  },
  {
    "en_nombre": "Hungary",
    "nombre": "Hungría"
  },
  {
    "en_nombre": "Iceland",
    "nombre": "Islandia"
  },
  {
    "en_nombre": "India",
    "nombre": "India"
  },
  {
    "en_nombre": "Indonesia",
    "nombre": "Indonesia"
  },
  {
    "en_nombre": "Iran, Islamic Republic Of",
    "nombre": "Irán"
  },
  {
    "en_nombre": "Iraq",
    "nombre": "Iraq"
  },
  {
    "en_nombre": "Ireland",
    "nombre": "Irlanda"
  },
  {
    "en_nombre": "Israel",
    "nombre": "Israel"
  },
  {
    "en_nombre": "Italy",
    "nombre": "Italia"
  },
  {
    "en_nombre": "Jamaica",
    "nombre": "Jamaica"
  },
  {
    "en_nombre": "Japan",
    "nombre": "Japón"
  },
  {
    "en_nombre": "Jordan",
    "nombre": "Jordania"
  },
  {
    "en_nombre": "Kazakhstan",
    "nombre": "Kazajstán"
  },
  {
    "en_nombre": "Kenya",
    "nombre": "Kenia"
  },
  {
    "en_nombre": "Kiribati",
    "nombre": "Kiribati"
  },
  {
    "en_nombre": "Korea, Democratic People'S Republic of",
    "nombre": "Corea del Norte"
  },
  {
    "en_nombre": "Korea, Republic of",
    "nombre": "Corea del Sur"
  },
  {
    "en_nombre": "Kuwait",
    "nombre": "Kuwait"
  },
  {
    "en_nombre": "Kyrgyzstan",
    "nombre": "Kirguistán"
  },
  {
    "en_nombre": "Lao People's Democratic Republic",
    "nombre": "Laos"
  },
  {
    "en_nombre": "Latvia",
    "nombre": "Letonia"
  },
  {
    "en_nombre": "Lebanon",
    "nombre": "Líbano"
  },
  {
    "en_nombre": "Lesotho",
    "nombre": "Lesotho"
  },
  {
    "en_nombre": "Liberia",
    "nombre": "Liberia"
  },
  {
    "en_nombre": "Libyan Arab Jamahiriya",
    "nombre": "Libia"
  },
  {
    "en_nombre": "Liechtenstein",
    "nombre": "Liechtenstein"
  },
  {
    "en_nombre": "Lithuania",
    "nombre": "Lituania"
  },
  {
    "en_nombre": "Luxembourg",
    "nombre": "Luxemburgo"
  },
  {
    "en_nombre": "Macao",
    "nombre": "Macao"
  },
  {
    "en_nombre": "Macedonia, The Former Yugoslav Republic of",
    "nombre": "Macedonia"
  },
  {
    "en_nombre": "Madagascar",
    "nombre": "Madagascar"
  },
  {
    "en_nombre": "Malawi",
    "nombre": "Malawi"
  },
  {
    "en_nombre": "Malaysia",
    "nombre": "Malasia"
  },
  {
    "en_nombre": "Maldives",
    "nombre": "Maldivas"
  },
  {
    "en_nombre": "Mali",
    "nombre": "Mali"
  },
  {
    "en_nombre": "Malta",
    "nombre": "Malta"
  },
  {
    "en_nombre": "Marshall Islands",
    "nombre": "Islas Marshall"
  },
  {
    "en_nombre": "Martinique",
    "nombre": "Martinica"
  },
  {
    "en_nombre": "Mauritania",
    "nombre": "Mauritania"
  },
  {
    "en_nombre": "Mauritius",
    "nombre": "Mauricio"
  },
  {
    "en_nombre": "Mayotte",
    "nombre": "Mayotte"
  },
  {
    "en_nombre": "Mexico",
    "nombre": "México"
  },
  {
    "en_nombre": "Micronesia, Federated States of",
    "nombre": "Estados Federados de Micronesia"
  },
  {
    "en_nombre": "Moldova, Republic of",
    "nombre": "Moldavia"
  },
  {
    "en_nombre": "Monaco",
    "nombre": "Mónaco"
  },
  {
    "en_nombre": "Mongolia",
    "nombre": "Mongolia"
  },
  {
    "en_nombre": "Montserrat",
    "nombre": "Montserrat"
  },
  {
    "en_nombre": "Morocco",
    "nombre": "Marruecos"
  },
  {
    "en_nombre": "Mozambique",
    "nombre": "Mozambique"
  },
  {
    "en_nombre": "Myanmar",
    "nombre": "Myanmar"
  },
  {
    "en_nombre": "Namibia",
    "nombre": "Namibia"
  },
  {
    "en_nombre": "Nauru",
    "nombre": "Nauru"
  },
  {
    "en_nombre": "Nepal",
    "nombre": "Nepal"
  },
  {
    "en_nombre": "Netherlands",
    "nombre": "Holanda"
  },
  {
    "en_nombre": "Netherlands Antilles",
    "nombre": "Antillas Holandesas"
  },
  {
    "en_nombre": "New Caledonia",
    "nombre": "Nueva Caledonia"
  },
  {
    "en_nombre": "New Zealand",
    "nombre": "Nueva Zelanda"
  },
  {
    "en_nombre": "Nicaragua",
    "nombre": "Nicaragua"
  },
  {
    "en_nombre": "Niger",
    "nombre": "Niger"
  },
  {
    "en_nombre": "Nigeria",
    "nombre": "Nigeria"
  },
  {
    "en_nombre": "Niue",
    "nombre": "Niue"
  },
  {
    "en_nombre": "Norfolk Island",
    "nombre": "Islas Norfolk"
  },
  {
    "en_nombre": "Northern Mariana Islands",
    "nombre": "Islas Marianas del Norte"
  },
  {
    "en_nombre": "Norway",
    "nombre": "Noruega"
  },
  {
    "en_nombre": "Oman",
    "nombre": "Omán"
  },
  {
    "en_nombre": "Pakistan",
    "nombre": "Pakistán"
  },
  {
    "en_nombre": "Palau",
    "nombre": "Palau"
  },
  {
    "en_nombre": "Palestinian Territory, Occupied",
    "nombre": "Palestina"
  },
  {
    "en_nombre": "Panama",
    "nombre": "Panamá"
  },
  {
    "en_nombre": "Papua New Guinea",
    "nombre": "Papua Nueva Guinea"
  },
  {
    "en_nombre": "Paraguay",
    "nombre": "Paraguay"
  },
  {
    "en_nombre": "Peru",
    "nombre": "Perú"
  },
  {
    "en_nombre": "Philippines",
    "nombre": "Filipinas"
  },
  {
    "en_nombre": "Pitcairn",
    "nombre": "Pitcairn"
  },
  {
    "en_nombre": "Poland",
    "nombre": "Polonia"
  },
  {
    "en_nombre": "Portugal",
    "nombre": "Portugal"
  },
  {
    "en_nombre": "Puerto Rico",
    "nombre": "Puerto Rico"
  },
  {
    "en_nombre": "Qatar",
    "nombre": "Qatar"
  },
  {
    "en_nombre": "Reunion",
    "nombre": "Reunión"
  },
  {
    "en_nombre": "Romania",
    "nombre": "Rumanía"
  },
  {
    "en_nombre": "Russian Federation",
    "nombre": "Rusia"
  },
  {
    "en_nombre": "Rwanda",
    "nombre": "Ruanda"
  },
  {
    "en_nombre": "Saint Helena",
    "nombre": "Santa Helena"
  },
  {
    "en_nombre": "Saint Kitts and Nevis",
    "nombre": "San Kitts y Nevis"
  },
  {
    "en_nombre": "Saint Lucia",
    "nombre": "Santa Lucía"
  },
  {
    "en_nombre": "Saint Vincent and the Grenadines",
    "nombre": "San Vicente y Granadinas"
  },
  {
    "en_nombre": "Samoa",
    "nombre": "Samoa"
  },
  {
    "en_nombre": "San Marino",
    "nombre": "San Marino"
  },
  {
    "en_nombre": "Sao Tome and Principe",
    "nombre": "Santo Tomé y Príncipe"
  },
  {
    "en_nombre": "Saudi Arabia",
    "nombre": "Arabia Saudita"
  },
  {
    "en_nombre": "Senegal",
    "nombre": "Senegal"
  },
  {
    "en_nombre": "Serbia and Montenegro",
    "nombre": "Serbia"
  },
  {
    "en_nombre": "Seychelles",
    "nombre": "Seychelles"
  },
  {
    "en_nombre": "Sierra Leone",
    "nombre": "Sierra Leona"
  },
  {
    "en_nombre": "Singapore",
    "nombre": "Singapur"
  },
  {
    "en_nombre": "Slovakia",
    "nombre": "Eslovaquía"
  },
  {
    "en_nombre": "Slovenia",
    "nombre": "Eslovenia"
  },
  {
    "en_nombre": "Solomon Islands",
    "nombre": "Islas Salomón"
  },
  {
    "en_nombre": "Somalia",
    "nombre": "Somalia"
  },
  {
    "en_nombre": "South Africa",
    "nombre": "Sudáfrica"
  },
  {
    "en_nombre": "Spain",
    "nombre": "España"
  },
  {
    "en_nombre": "Sri Lanka",
    "nombre": "Sri Lanka"
  },
  {
    "en_nombre": "Sudan",
    "nombre": "Sudán"
  },
  {
    "en_nombre": "Surien_nombre",
    "nombre": "Surinam"
  },
  {
    "en_nombre": "Swaziland",
    "nombre": "Swazilandia"
  },
  {
    "en_nombre": "Sweden",
    "nombre": "Suecia"
  },
  {
    "en_nombre": "Switzerland",
    "nombre": "Suiza"
  },
  {
    "en_nombre": "Syrian Arab Republic",
    "nombre": "Siria"
  },
  {
    "en_nombre": "Taiwan, Province of China",
    "nombre": "Taiwán"
  },
  {
    "en_nombre": "Tajikistan",
    "nombre": "Tadjikistan"
  },
  {
    "en_nombre": "Tanzania, United Republic of",
    "nombre": "Tanzania"
  },
  {
    "en_nombre": "Thailand",
    "nombre": "Tailandia"
  },
  {
    "en_nombre": "Timor-Leste",
    "nombre": "Timor Oriental"
  },
  {
    "en_nombre": "Togo",
    "nombre": "Togo"
  },
  {
    "en_nombre": "Tokelau",
    "nombre": "Tokelau"
  },
  {
    "en_nombre": "Tonga",
    "nombre": "Tonga"
  },
  {
    "en_nombre": "Trinidad and Tobago",
    "nombre": "Trinidad y Tobago"
  },
  {
    "en_nombre": "Tunisia",
    "nombre": "Túnez"
  },
  {
    "en_nombre": "Turkey",
    "nombre": "Turquía"
  },
  {
    "en_nombre": "Turkmenistan",
    "nombre": "Turkmenistan"
  },
  {
    "en_nombre": "Turks and Caicos Islands",
    "nombre": "Islas Turcas y Caicos"
  },
  {
    "en_nombre": "Tuvalu",
    "nombre": "Tuvalu"
  },
  {
    "en_nombre": "Uganda",
    "nombre": "Uganda"
  },
  {
    "en_nombre": "Ukraine",
    "nombre": "Ucrania"
  },
  {
    "en_nombre": "United Arab Emirates",
    "nombre": "Emiratos Árabes Unidos"
  },
  {
    "en_nombre": "United Kingdom",
    "nombre": "Reino Unido"
  },
  {
    "en_nombre": "United States",
    "nombre": "Estados Unidos"
  },
  {
    "en_nombre": "Uruguay",
    "nombre": "Uruguay"
  },
  {
    "en_nombre": "Uzbekistan",
    "nombre": "Uzbekistán"
  },
  {
    "en_nombre": "Vanuatu",
    "nombre": "Vanuatu"
  },
  {
    "en_nombre": "Venezuela",
    "nombre": "Venezuela"
  },
  {
    "en_nombre": "Viet Nam",
    "nombre": "Vietnam"
  },
  {
    "en_nombre": "Virgin Islands, British",
    "nombre": "Islas Vírgenes Británicas"
  },
  {
    "en_nombre": "Virgin Islands, U.S.",
    "nombre": "Islas Vírgenes Americanas"
  },
  {
    "en_nombre": "Wallis and Futuna",
    "nombre": "Wallis y Futuna"
  },
  {
    "en_nombre": "Western Sahara",
    "nombre": "Sáhara Occidental"
  },
  {
    "en_nombre": "Yemen",
    "nombre": "Yemen"
  },
  {
    "en_nombre": "Zambia",
    "nombre": "Zambia"
  },
  {
    "en_nombre": "Zimbabwe",
    "nombre": "Zimbabwe"
  }
]
